import { takeEvery, call, put } from 'redux-saga/effects';

import { post } from 'util/api/saga';
import { pending, notify } from 'state/actions/ui';

import {
    currentTabUrl,
    chromeApi
} from "joynt/chrome-extension/chrome";

//const postTypeId = '87e5903b-2d01-45b6-a497-42933c9a8f83';
const linkTypeId = 'cea8ad00-3d13-428f-a57d-96ee19758d4a';
const quoteTypeId = '3a26ea82-8b67-43f3-aea8-3f8e305b61e6';

function* handleAddPost(action) {
    try {
        const { context, payload } = action;
        const { parent, stem } = payload;
        const postData = payload.data || {};
        const chrome = chromeApi();
        if (!chrome) return;
        const tabUrl = yield call(currentTabUrl);
        const data = {
            subtype: stem ? quoteTypeId : linkTypeId,
            active: true,
            link: tabUrl,
            parent: stem,
            parent_node: parent,
            content: '',
            ...postData
        };
        yield put(pending('add-post', true));
        yield call(post, data, 'joynt/posts', null, context);
        yield put(pending('add-post', false));
        yield put(notify('Added to joint', 'success'));
    } catch (e) {
        yield put(pending('add-post', false));
        yield put(notify('Something went wrong', 'error'));
        throw e;
    }
}

export default function*() {
    yield takeEvery('JOINT.ADD_POST', handleAddPost);
    yield takeEvery('JOINT.ADD_FRAGMENT', handleAddPost);
}
import { convertFromHTML } from "draft-convert";

export function createContentStateFromHTML(html) {
    return convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === "a") {
                return createEntity("LINK", "MUTABLE", { url: node.href });
            }
        },
    })(html);
}

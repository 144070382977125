import {
    takeEvery,
    put
} from 'redux-saga/effects';

import {handlePost} from "state/sagas/api";
import {deleteSuccess} from "state/actions/delete";

function* handleStartMeeting({payload, context}) {
    try {
        const url = `joynt/nodes/${payload.id}/meeting`;
        return yield handlePost({context, payload: {
            url,
            submitId: `meeting.${payload.id}`
        }});
    } catch (e) {
        console.log(e);
    }
}

function* handleLeaveMeeting({payload, context}) {
    try {
        const { id } = payload;
        const url = `joynt/nodes/${id}/leave`;
        yield put(deleteSuccess('db.meetings-access', id));
        return yield handlePost({context, payload: {
            url,
            submitId: `meeting.${id}`
        }});
    } catch (e) {
        console.log(e);
    }
}

export default function*() {
    yield takeEvery('CALL.START', handleStartMeeting);
    yield takeEvery('CALL.LEAVE', handleLeaveMeeting);
}
import { getIn, setIn } from "lodash-redux-immutability";
import { createReducer } from "state/helpers/create-reducer";
import { get, uniq } from "lodash";

function append(state, path, id, position = null) {
    let prev = get(state, path, []);
    let next = prev.slice();
    if (next.indexOf(id) > -1) return state;
    if (position === null) next.push(id);
    if (position === -1) next.unshift(id);
    return setIn(state, path, uniq(next));
}

function remove(list, id) {
    if (!list) return list;
    if (list.indexOf(id) === -1) return list;
    list = list.slice();
    return list.filter((item) => item !== id);
}

function removeFromList(state, path, id) {
    return setIn(state, path, remove(getIn(state, path), id));
}

function removeFromAll(state, type, id) {
    const lists = state[type] || {};

    Object.keys(lists).forEach((listId) => {
        let listItems = get(state, [type, listId]);
        let nextItems = remove(listItems, id);
        if (nextItems !== listItems) {
            state = setIn(state, [type, listId], nextItems);
        }
    });
    return state;
}

const actions = {
    "DATA.STORE.LIST": (state, { payload }) => {
        const { type, list, data, append, position } = payload;
        if (!list) return state;
        if (!data.map) console.log(data);
        const ids = data.map((item) => item.id);
        if (append) {
            const prev = getIn(state, [type, list]) || [];
            const next = position === -1 ? ids.concat(prev) : prev.concat(ids);

            return setIn(state, [type, list], uniq(next));
        }
        return setIn(state, [type, list], ids);
    },
    "DATA.STORE.LIST_IDS": (state, { payload }) => {
        const { type, list, data } = payload;
        return setIn(state, [type, list], uniq(data));
    },
    "DATA.STORE.BATCH": (state, { payload }) => {
        const { meta } = payload;
        if (meta.lists) {
            Object.keys(meta.lists).forEach((listId) => {
                let [type, list] = listId.split("/");
                state = setIn(state, [type, list], uniq(meta.lists[listId]));
            });
        }
        return state;
    },
    "DATA.LIST.DELETE": (state, { payload }) => {
        const { type, list, item } = payload;
        let prev = get(state, [type, list], []);
        let next = remove(prev, item);
        return setIn(state, [type, list], next);
    },
    "DATA.LIST.APPEND": (state, { payload }) => {
        const { type, list, item, position } = payload;
        let path = [type, list];
        return append(state, path, item, position);
    },
    "DATA.LIST.CREATE": (state, { payload }) => {
        const { type, list, item, position } = payload;
        return append(state, [type, list], item.id, position);
    },
    "DATA.LIST.RESET": (state, { payload }) => {
        const { type, list } = payload;
        return setIn(state, [type, list], []);
    },
    "DATA.LIST.SORT": (state, { drag, hover }) => {},
    "DELETE.SUCCESS": (state, { payload }) => {
        const { type, id } = payload;
        return removeFromAll(state, type, id);
    },
    "DELETE.SUCCESS.BATCH": (state, { payload }) => {
        let next = { ...state };
        Object.keys(payload).forEach((type) => {
            payload[type].forEach((id) => {
                next = removeFromAll(next, type, id);
            });
        });
        return next;
    },
    "LIST.APPEND": (state, { payload }) => {
        const { type, list, id, position } = payload;
        return append(state, [type, list], id, position);
    },
    "LIST.REMOVE": (state, { payload }) => {
        const { type, list, id } = payload;
        return removeFromList(state, [type, list], id);
    },
};

export default createReducer({}, actions);

import WebFont from 'webfontloader';

const loadedFonts = [];

export const loadWebfont = function(data) {
    return new Promise((resolve, reject) => {
        const { family, source } = data;
        if (loadedFonts.indexOf(family) > -1) return;
        loadedFonts.push(family);
        if (source === "google") {
            WebFont.load({
                google: {
                    families: [family]
                }
            });
        } else {
            WebFont.load({
                custom: {
                    families: [family],
                    urls: [
                        "https://s3.eu-central-1.amazonaws.com/zuu-webfonts/" +
                        family +
                        "/stylesheet.css"
                    ]
                }
            });
        }
        resolve();
    });
};
import { createReducer } from "../helpers/create-reducer";
import { setIn, getIn } from "lodash-redux-immutability";

const actions = {
    "NOTIFICATIONS.CONSOLE.ADD": (state, { payload }) => {
        const { user, message } = payload;
        const context = payload.context || {};
        const { id, task_id, status, created_at } = context;
        const path = ["notifications", id];
        const prev = getIn(state, path);
        const nextStatus = id === task_id ? status : prev?.status;

        if (!prev) {
            return setIn(state, path, {
                id,
                message,
                status: nextStatus,
                created_at,
                log: {},
            });
        }
        if (task_id !== id) {
            return setIn(state, path, {
                ...prev,
                status: nextStatus,
                log: setIn(prev.log, [task_id], {
                    message,
                    status,
                    ...context,
                }),
            });
        }
        return setIn(state, path, {
            ...prev,
            status: nextStatus,
        });
    },
};
export default createReducer(
    {
        notifications: {},
    },
    actions
);

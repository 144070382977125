import { takeEvery } from "redux-saga/effects";

import { handleFlowSegmentTransition } from "./handleFlowSegmentTransition";
import { handleSetSessionFlowSegment } from "./handleSetSessionFlowSegment";

export default function* () {
    yield takeEvery(
        "MEETING.SESSION.SET_FLOW_SEGMENT",
        handleSetSessionFlowSegment
    );
    yield takeEvery(
        "JOINT.SESSION.FLOW_SEGMENT_CHANGED",
        handleFlowSegmentTransition
    );
}

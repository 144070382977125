import { takeEvery, put } from "redux-saga/effects";
import { setFlag } from "state/actions/ui";

function* handleJoyrideId({ payload }) {
    const { id } = payload;

    try {
        yield put(setFlag("joyride.id", id));
    } catch (e) {
        console.log("Error in joyride saga!");
        throw e;
    }
}

function* handleJoyrideRun({ payload }) {
    const { run } = payload;

    try {
        yield put(setFlag("joyride.run", run));
    } catch (e) {
        console.log("Error in joyride saga!");
        throw e;
    }
}

export default function* () {
    yield takeEvery("JOYRIDE.ID.SET", handleJoyrideId);
    yield takeEvery("JOYRIDE.RUN.SET", handleJoyrideRun);
}

import { takeEvery, putResolve as put, select } from "redux-saga/effects";

import { notify, pending, setFlag } from "state/actions/ui";

import {
    storeList,
    storeOne,
    storeAlias,
    reset,
    storeBatch,
    resetBatch,
    storeListIds,
    resetAll,
    change,
} from "state/actions/data";

import {
    selectEntity,
    selectIsDraft,
    selectListChanges,
} from "state/selectors/data";
import { FILTER_NS } from "config/list-aliases";
import { denormalize } from "state/util";
import { deleteFailed, deleteSuccess } from "state/actions/delete";
import { getIn } from "lodash-redux-immutability";
import { fetch } from "state/actions/api";
import parseApiResponse from "util/api/adapters";
import { request as apiRequest } from "util/api/client";

const parseResponse = (type, response) => {
    let parsed = parseApiResponse(type, response);
    return parsed.data.data;
};

const parseMeta = (type, response) => response.data.meta;

function* handleFetch(action) {
    const { payload, context } = action;
    const { type, list, url, view, filter, append, fetchId, yieldData } =
        payload;
    const listId = [type, list].join("/");
    const pendingId = fetchId || listId;
    try {
        let query = {};
        if (view) query.view = view;
        const filterData = filter
            ? yield select((store) => selectEntity(store, FILTER_NS, filter))
            : {};
        for (let k in filterData) {
            if (filterData[k] === null) delete filterData[k];
        }
        query = { ...query, ...filterData };
        if (!query.page) delete query.page;
        if (!url) return;
        const appendToList = query.page === 1 ? false : append;
        yield put(pending(pendingId, true));
        //const response = yield call(api.getArgs, query, url, null, context);
        const response = yield apiRequest({ url, context, query });
        if (response) {
            const data = parseResponse(type, response);
            const meta = parseMeta(type, response);
            if (meta && meta.batch) {
                yield put(storeBatch(data, meta));
            } else {
                yield put(
                    storeList(type, list, data, meta, appendToList, yieldData)
                );
            }
        }
        yield put(pending(pendingId, false));
    } catch (e) {
        yield put(pending(pendingId, false));
        console.log(e);
    }
}

export function* handleFetchEntity(action) {
    try {
        const { payload, context } = action;
        const { type, id, url, alias, fetchId } = payload;
        const query = { id };
        const apiUrl = url || [type, ":id"].join("/");
        if (id) yield put(pending(id, true));
        if (alias) yield put(pending(alias, true));
        if (fetchId) yield put(pending(fetchId, true));
        const response = yield apiRequest({ context, query, url: apiUrl });
        if (response) {
            if (type === "db.enum-values") {
                payload.type = "db.enum-definitions";
                payload.alias = response.data.data.slug;
            }
            if (type === "cms.enum-values") {
                payload.type = "cms.enum-definitions";
                payload.alias = response.data.data.slug;
            }
            yield handleResponse(payload, response);
            if (id) yield put(pending(id, false));
            if (alias) yield put(pending(alias, false));
            if (fetchId) yield put(pending(fetchId, false));
        }
        return response;
    } catch (e) {
        const { id, alias, fetchId } = action.payload;
        if (id) yield put(pending(id, false));
        if (alias) yield put(pending(alias, false));
        if (fetchId) yield put(pending(fetchId, false));
        if (fetchId) yield put(setFlag(`failedRequest.${fetchId}`, e.message));
        console.log(e);
    }
}

export function* handleResponse(payload, response) {
    try {
        const { type, id, alias } = payload;
        const data = parseResponse(type, response);
        const responseMeta = parseMeta(type, response);
        if (responseMeta && responseMeta.batch) {
            yield put(storeBatch(data, responseMeta));
        } else {
            if (type && data) yield put(storeOne(type, data.id || id, data));
            if (type && data && alias)
                yield put(storeAlias(type, alias, data.id));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleSubmitList(action) {
    const { payload, context } = action;
    const { type, list, url } = payload;
    let listId = [type, list].join("/");
    try {
        const data = yield select((store) =>
            selectListChanges(store, type, list)
        );
        yield put(pending(listId, true));
        yield apiRequest({ context, method: "post", url, data });
        yield put(pending(listId, false));
        console.log(data);
    } catch (e) {
        yield put(pending(listId, false));
        console.log(e);
    }
}

export function* handleSubmit(action) {
    const { payload, context, callback } = action;
    const { type, id, url, submitId, data } = payload;
    const pendingId = submitId || id;
    let errors = false;
    let appendData = data || {};
    try {
        const entity = yield select((store) => {
            let data = denormalize(store, type, id);

            /**
             * draft-js content gets messed up when merging
             * data and changes while it makes no sense anyway
             * because the state in changes is always complete
             */
            if (type === "db.nodes") {
                delete data.content;
                if (store.changes[type]?.[id]?.content) {
                    data.content = store.changes[type]?.[id]?.content;
                }
            }
            return data;
        });

        const postData = { id, ...entity, ...appendData };
        const defaultUrl = type.replace(".", "/") + "/:id";
        let useUrl = url || defaultUrl;
        useUrl += "?raw=true";
        yield put(pending(pendingId, true));
        const response = yield apiRequest({
            context,
            method: "put",
            data: postData,
            url: useUrl,
        });
        if (response) {
            yield handleResponse(payload, response);
            const data = parseResponse(type, response) || {};
            if (data.id) yield put(storeOne(type, id, data));
            errors = response.data.errors;
        }
        yield put(pending(pendingId, false));
        if (errors) {
            yield put(notify("Validation failed", "error"));
        } else {
            //yield put(notify('Saved', 'success'));
        }
        let responseId = data ? data.id : id;
        if (callback) yield callback();

        yield postSubmitCleanup(type, response, responseId);
    } catch (e) {
        yield put(pending(pendingId, false));
        console.log(e);
    }
}

function* postSubmitCleanup(type, response, id) {
    try {
        const data = parseResponse(type, response);
        const responseId = data.id || id;
        if (responseId) yield put(reset(type, responseId));
        if (type === "cms.enum-definitions" && data) {
            const paths = data?.values?.map((v) => `cms.enum-values/${v.id}`);
            yield put(resetBatch(paths));
        }
    } catch (e) {
        console.log(e);
    }
}

export function* handlePost(action) {
    const { payload, context } = action;
    const { url, data, submitId } = payload;
    try {
        yield put(pending(submitId, true));
        const response = yield apiRequest({
            context,
            method: "post",
            url,
            data,
        });
        if (response) yield handleResponse(payload, response);
        yield put(pending(submitId, false));
        return response;
    } catch (e) {
        yield put(pending(submitId, false));
        console.log(e);
    }
}

export function* handlePut(action) {
    const { payload, context } = action;
    const { url, data, submitId } = payload;
    try {
        yield put(pending(submitId, true));
        const response = yield apiRequest({
            context,
            method: "put",
            url,
            data,
        });
        if (response) yield handleResponse(payload, response);
        yield put(pending(submitId, false));
        return response;
    } catch (e) {
        yield put(pending(submitId, false));
        console.log(e);
    }
}

function* handleDelete({ payload, context, callback }) {
    const { type, id, url } = payload;
    try {
        const useUrl = url || type.replace(".", "/");
        const isDraft = yield select((store) => selectIsDraft(store, type, id));
        if (!isDraft) {
            yield put(pending(id, true));
            yield apiRequest({
                context,
                method: "delete",
                url: useUrl,
                query: { id },
            });
            yield put(pending(id, false));
        }
        yield put(deleteSuccess(type, id));
        if (callback) callback(type, id);
    } catch (e) {
        yield put(deleteFailed(type, id));
        yield put(pending(id, false));
        console.log(e);
    }
}

function* handleReload({ payload, context }) {
    const { type, list } = payload;
    try {
        const props = yield select((store) =>
            getIn(store["lists-meta"], ["_props", type, list])
        );
        if (!props) return;
        yield put(change(FILTER_NS, props.filter, { page: 1 }));

        yield put(
            fetch(context)(
                props.type,
                props.list,
                props.url,
                props.view,
                props.filter
            )
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleSubmitBatch({ payload, context, callback }) {
    const { url, paths, submitId, reset } = payload;
    try {
        yield put(pending(submitId, true));
        const data = {};
        for (let i = 0; i < paths.length; i++) {
            let [type, id] = paths[i].split("/");
            let item = yield select((s) => denormalize(s, type, id));
            if (!data[type]) data[type] = [];
            data[type].push(item);
        }
        const response = yield apiRequest({
            context,
            method: "put",
            url,
            data,
        });
        yield handleResponse(payload, response);
        if (reset) {
            yield put(resetAll());
        } else {
            yield put(resetBatch(paths));
        }
        yield put(pending(submitId, false));
        if (callback) callback(true);
    } catch (e) {
        yield put(pending(submitId, false));
        if (callback) callback(false);
        console.log(e);
    }
}

function* handleSubmitOrder({ payload, context }) {
    const { url, type, list, order } = payload;
    try {
        const data = {};
        for (let i = 0; i < order.length; i++) {
            data[order[i]] = i;
        }
        const useUrl = url || type.replace(/\//g, ".");
        yield put(pending([type, list].join("/"), true));
        yield put(storeListIds(type, list, order));
        yield apiRequest({ context, method: "put", url: useUrl, data });
        yield put(pending([type, list].join("/"), false));
        yield put(notify("Order saved", "success"));
    } catch (e) {
        console.log(e);
    }
}

function* handleOAuthFlowInit({ payload, context }) {
    const { url } = payload;
    try {
        yield put(pending("oauth-init", true));
        const data = {
            redirect_uri: window.location.href,
        };
        const response = yield apiRequest({
            context,
            method: "post",
            url,
            data: { data },
        });
        const authUrl = response.data.data.auth_url;
        if (authUrl) {
            window.location.href = authUrl;
        } else {
            yield put(pending("oauth-init", false));
        }
    } catch (e) {
        yield put(pending("oauth-init", false));
        console.log(e);
    }
}

function* handleApiException({ payload }) {
    try {
        const { error } = payload;
        yield put(notify(error.message, "error"));
    } catch (e) {}
}

export default function* () {
    yield takeEvery("API.FETCH", handleFetch);
    yield takeEvery("API.FETCH_ONE", handleFetchEntity);
    yield takeEvery("API.SUBMIT_LIST", handleSubmitList);
    yield takeEvery("API.SUBMIT_ONE", handleSubmit);
    yield takeEvery("API.DELETE", handleDelete);
    yield takeEvery("API.POST", handlePost);
    yield takeEvery("API.PUT", handlePut);
    yield takeEvery("API.SUBMIT_BATCH", handleSubmitBatch);
    yield takeEvery("API.SUBMIT_ORDER", handleSubmitOrder);
    yield takeEvery("LIST.RELOAD", handleReload);
    yield takeEvery("API.OAUTH.INIT", handleOAuthFlowInit);
    yield takeEvery("API.EXCEPTION", handleApiException);
}

import { loadWebfont} from "util/theme/font-loader";

const mapping = {
    color_scheme: {
        dark_color: "dark-color",
        light_color: "light-color",
        primary_color: "brand-primary",
        success_color: "brand-success"
    }
};

const applyMapping = function(preset, vars, key) {
    var scopeMapping = mapping[key];
    var scopeData = preset[key];
    for (var k in scopeMapping)
        if (scopeMapping.hasOwnProperty(k)) {
            if (scopeData[k]) {
                var mappedKey = "@" + scopeMapping[k];
                vars[mappedKey] = scopeData[k];
            }
        }
    return vars;
};

export const convertTypeStyleToVars = style => {
    let vars = {};
    let ids = [];
    Object.keys(style).forEach((k) => {
        if (k !== 'id') {
            let lessVarName = '@' + [
                style.id,
                k.replace(/_/g, '-')
            ].join('-');
            ids.push(style.id);
            let v = style[k];
            if (k === 'font' && !v) v = 'body';
            if (k === 'font_weight' && !v) v = 'regular';
            vars[lessVarName] = v;
        }
    });
    vars['@type-styles-list'] = ids.join(',');
    return vars;
};

export const convertTypeStylesToVars = styles => {
    let vars = {};
    if (styles && styles.length > 0) {
        vars['@type-styles-list'] = styles.map(s => s.id).join(',');
        styles.forEach((style) => {
            let styleVars = convertTypeStyleToVars(style);
            vars = {...vars, ...styleVars};
        });
    }
    return vars;
};

export const convertFonts = (vars, fonts, load) => {
    let fontVars = {};
    let takeProps = ['body_font', 'heading_font', 'decorative_font'];
    for (let i = 0; i < takeProps.length; i++) {
        let k = takeProps[i];
        let fontName = k.replace('_', '-');
        if (load) loadWebfont(fonts[k]);
        for (let p in fonts[k]) {
            let propName = p.replace('_', '-');
            let path = p === 'family'
                ? `@${fontName}`
                : `@${fontName}-${propName}`;
            if (fonts[k][p]) fontVars[path] = fonts[k][p];
        }
    }
    return {...vars, ...fontVars};
};

export const convertPresetToLessVars = (preset, loadFonts) => {
    let vars = {};
    vars = applyMapping(preset, vars, "color_scheme");
    vars = applyMapping(preset, vars, "typography");
    vars = convertFonts(vars, preset.typography, false);
    if (preset.mapping) { vars["@color-mapping"] = preset.mapping; }
    vars["@enable-reveal-effects"] = 0;
    let typeStyles = convertTypeStylesToVars(preset.typography.styles);
    return {...vars, ...typeStyles};
};

export const responsivePreviewBreakpoints = device => {
    if (!device || device === 'desktop') return {};
    return {
        '@xss-min': '500px',
        '@xs-min': '1680px',
        '@sm-min': '2000px',
        '@md-min': '2000px',
        '@lg-min': '2000px',
        '@xlg-min': '2000px',
        '@container-width': '375px'
    }
};


import { request } from "util/api/client";
import { put } from "redux-saga/effects";
import { pending } from "state/actions/ui";
import { storeOne } from "state/actions/data";
import { joinMeeting } from "joynt/state/actions/meetings";

export function* handleStartSession({ context, payload }) {
    const { id } = payload;
    try {
        const url = `joynt/nodes/${id}/witness/start`;
        yield put(pending(id, true));
        const {
            data: { data },
        } = yield request({
            url,
            method: "post",
            context,
        });
        yield put(storeOne("db.nodes", data.id, data));
        yield put(pending(id, false));

        const session = data.task_witness_session;

        if (session) {
            yield put(joinMeeting(context)(session));
        }
    } catch (e) {
        yield put(pending(id, false));
        console.error(e);
    }
}

import { takeEvery, put, select } from "redux-saga/effects";

import uuid from "uuid/v4";

import { change, storeList, storeOne } from "state/actions/data";
import { create } from "state/actions/create";
import { post } from "state/actions/api";
import { selectEntity } from "state/selectors/data";
import { selectList } from "state/selectors/lists";
import { handlePost } from "state/sagas/api";
import { request } from "util/api/client";
import { selectRouteParam } from "state/selectors/router";
import { pending, setFlag } from "state/actions/ui";
import {
    FLAG_CHAT_THREAD,
    FLAG_LAYOUT_COLLAPSE,
    FLAG_SESSION_ID,
    FLAG_SESSION_NAV,
    FLAG_UTILITY_NAV,
    ROUTE_CHANNEL,
    ROUTE_THREAD,
    ROUTE_VIEW,
} from "joynt/config";
import { createThread, showChat } from "joynt/state/actions/messages";
import { pushRouteParams } from "state/actions/router";
import { selectFlag } from "state/selectors/ui";
import { isSessionChat, selectEdges } from "joynt/state/selectors/joint";

function* handleCreate({ context, payload }) {
    try {
        let { message, nodeId, thread, threadType } = payload;

        thread = thread
            ? yield select((store) => selectEntity(store, "db.threads", thread))
            : { id: uuid(), type: "threads", thread_type: threadType };

        let node = nodeId
            ? yield select((store) => selectEntity(store, "db.nodes", nodeId))
            : { id: uuid(), type: "nodes" };

        message.parent = thread.id;

        let request = {
            message,
            thread,
            node,
            parent_node: nodeId,
        };

        const list = ["thread", thread.id].join(".");
        yield put(create("db.messages", { ...message }, list, -1));

        const response = yield handlePost(
            post(context)("joynt/messages", request, message.id)
        );

        const messageData = response.data.data;
        yield put(storeOne("db.messages", messageData.id, messageData));
        yield pushMessage(messageData);
    } catch (e) {
        console.log(e);
    }
}

function* pushMessage(message, list) {
    yield put(
        change("db.threads", message.parent, { last_message: message.id })
    );
    if (list) {
        const listData = yield select((store) =>
            selectList(store, "db.messages", list)
        );
        if (listData.indexOf(message.id) === -1) {
            yield put(
                storeList("db.messages", list, [message], null, true, false, -1)
            );
        }
    }
}

function* handlePush({ payload }) {
    const { message } = payload;
    try {
        yield pushMessage(message, ["thread", message.parent].join("."));
    } catch (e) {
        throw e;
    }
}

function* handleFetchMentions({ context, payload }) {
    try {
        const { search, callback, id } = payload;
        const workspace = yield select((s) => selectRouteParam(s, "id"));
        const node = id || workspace;
        const url = `v2/joynt/nodes/${node}/participants`;
        const query = { limit: 10 };
        if (search) query.search = search;
        const response = yield request({
            context,
            url,
            query,
        });
        const {
            data: { data },
        } = response;
        const mapped = data.map((identity) => ({
            id: identity.id,
            name: identity.name,
            display: identity.name,
        }));
        const withAll = [
            {
                id: "all",
                name: "all",
                display: "all",
            },
        ].concat(mapped);
        callback(withAll);
    } catch (e) {
        console.log(e);
    }
}

function* handleShowChat({ payload: { id } }) {
    const isMobile = yield select((s) => s?.ui?.screenSize?.isMobile);
    const session = yield select((s) => selectFlag(s, FLAG_SESSION_ID));

    if (isMobile) {
        if (session) {
            const isSessionChatMessage = yield select((s) =>
                isSessionChat(s, id, session)
            );
            if (isSessionChatMessage) {
                yield put(
                    setFlag([FLAG_LAYOUT_COLLAPSE, session].join("."), true)
                );
                yield put(setFlag(FLAG_SESSION_NAV, "main-chat"));
            } else {
                // Exit fullscreen session
                yield put(
                    setFlag([FLAG_LAYOUT_COLLAPSE, session].join("."), false)
                );
            }
        }
        const isMainChat = yield select((s) => isSessionChat(s, id));

        if (isMainChat) {
            yield put(pushRouteParams({ [ROUTE_VIEW]: "main-chat" }));
            return;
        }
        // Link to inbox
        yield put(
            pushRouteParams({
                [ROUTE_VIEW]: "inbox",
                [ROUTE_THREAD]: id,
            })
        );
    } else {
        // Show chat popup
        yield put(setFlag(FLAG_CHAT_THREAD, id));
        yield put(setFlag(FLAG_UTILITY_NAV, null));
    }
}

function* handleChat({ context, payload }) {
    try {
        const { parent, id } = payload;
        yield put(pending("chat", true));
        yield put(setFlag(FLAG_UTILITY_NAV, null));

        const {
            data: { data: info },
        } = yield request({
            context,
            url: `joynt/identities/${id}/info?parent=${parent}`,
        });
        yield put(storeOne("db.identity-info", id, info));
        let prevChat = info.last_thread;
        if (prevChat) {
            yield put(showChat(prevChat));
            yield put(pending("chat", false));
            return;
        }
        const chatId = uuid();
        yield put(
            createThread(null, parent, null, [id], chatId, "private-chat")
        );
        yield put(showChat(chatId));
        yield put(pending("chat", false));
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield takeEvery("MESSAGES.CREATE", handleCreate);
    yield takeEvery("MESSAGES.PUSH", handlePush);
    yield takeEvery("MESSAGES.START_CHAT", handleChat);
    yield takeEvery("MESSAGES.SHOW_CHAT", handleShowChat);
    yield takeEvery("JOINT.MENTIONS", handleFetchMentions);
}

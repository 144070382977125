import { takeEvery, put, race, take, select, all } from "redux-saga/effects";

import { create, createCallback } from "state/actions/create";

import uuid from "uuid/v4";
import {
    change,
    pathAppend,
    pathChange,
    pathDetach,
    resetBatch,
    storeOne,
} from "state/actions/data";
import { setFlag } from "state/actions/ui";
import { submitOne } from "state/actions/api";
import { selectById, selectEntity, selectIsDraft } from "state/selectors/data";
import {
    FLAG_NEW_POST_ID,
    FLAG_NEW_POST_TYPE,
    POST_TYPE_ALBUM,
    POST_TYPE_LINK,
    POST_TYPE_POLL,
    POST_TYPE_TEXT,
    ROUTE_POST,
} from "joynt/config";
import { handleUploadCallback } from "state/actions/upload";
import extractLinks from "util/extractLinks";
import { pushRouteParams } from "state/actions/router";
import { listAppend } from "state/actions/list";
import {
    createPostReject,
    resetPost,
    submitPost,
} from "joynt/state/actions/posts";
import {
    selectNewTags,
    selectPostContentStructured,
} from "joynt/state/selectors/joint";
import { request } from "util/api/client";
import { deleteSuccess } from "state/actions/delete";
import { convertPostsToRawState } from "joynt/components/ContentEditor/util";
/**
 * Create a new post with content blocks
 *
 * @param context
 * @param payload
 * @returns {Generator<*, void, *>}
 */
function* handleCreatePost({ context, payload }) {
    try {
        const { callback, data, list } = payload;
        const posts = data.posts || [];

        posts.forEach(function (post) {
            post.parent = data.id;
        });

        data.posts = posts.map((post) => post.id);

        let createdPosts = [];

        for (let post of posts) {
            let createdPostId = yield handleCreatePostContent({
                context,
                payload: { data: post },
            });
            if (createdPostId) createdPosts.push(createdPostId);
        }
        if (createdPosts.length < data.posts.length) return;

        const prevData = yield select((s) =>
            selectEntity(s, "db.nodes", data.id)
        );
        if (prevData.name && !data.name) data.name = prevData.name;

        data.posts = createdPosts;
        data.node_type = "stem";

        const { published } = yield select((s) =>
            selectEntity(s, "db.nodes", data.parent)
        );

        const access = {
            public: true,
            can_edit: true,
            published: true //!!published,
        };

        const createData = {
            ...access,
            ...data,
        };

        if (list) {
            yield put(
                createCallback(callback, "db.nodes", createData, list, -1)
            );
        } else {
            yield put(createCallback(callback, "db.nodes", createData));
        }
    } catch (e) {
        console.log(e);
    }
}

/**
 * Append post content block
 *
 * @param context
 * @param payload
 */
function* handleCreatePostContent({ context, payload, cb }) {
    const { data } = payload;
    try {
        if (!data.parent) {
            throw new Error("Node id not provided for post");
        }
        if (!data.subtype) {
            throw new Error("Post type not provided");
        }
        const { parent, subtype: type, files } = data;
        delete data.files;
        const havePrompt = [POST_TYPE_LINK, POST_TYPE_ALBUM];
        const path = ["db.nodes", parent, "posts"];
        const id = data.id || uuid();
        const item = { id, ...data };
        yield put(create("db.posts", item));
        if (files) {
            yield handlePostUploadMultiple({
                context,
                payload: { id: parent, files, post: id },
            });
        }
        if (havePrompt.indexOf(type) > -1) {
            yield put(setFlag("posts.create", id));
            const { confirm } = yield race({
                confirm: take("UI.PROMPT.CONFIRM"),
                cancel: take("UI.PROMPT.CANCEL"),
            });
            yield put(setFlag("posts.create", null));
            if (confirm) {
                yield put(pathAppend(path, id));
                yield handlePostContentCreated({
                    context,
                    payload: { parent, id },
                });
                if (cb) cb(item);
                return id;
            }
        } else {
            yield put(pathAppend(path, id));
            if (cb) cb(item);
            return id;
        }
    } catch (e) {
        console.error(e);
    }
}

function* handlePostContentCreated({ context, payload: { parent, id } }) {
    try {
        const { name } = yield select((s) =>
            selectEntity(s, "db.nodes", parent)
        );
        const postData = yield select((s) => selectEntity(s, "db.posts", id));
        const { subtype } = postData;
        if (!name && subtype === POST_TYPE_LINK && postData.link) {
            const {
                data: { data: linkData },
            } = yield request({
                url: `embed`,
                context,
                query: { url: postData.link },
            });
            if (linkData.title) {
                yield put(
                    change(
                        "db.nodes",
                        parent,
                        {
                            name: linkData.title,
                        },
                        true
                    )
                );
            }
        }
        return null;
    } catch (e) {
        console.error(e);
    }
}

function* handleSubmit({ context, payload: { id, share } }) {
    try {
        const data = yield select((s) => selectEntity(s, "db.nodes", id));
        const { posts: postsIds } = data;
        const posts = yield select((s) =>
            selectById(s, "db.posts", postsIds || [])
        );
        const types = posts.map((p) => p.subtype);

        if (
            types.indexOf(POST_TYPE_TEXT) > -1 &&
            types.indexOf(POST_TYPE_LINK) === -1
        ) {
            let textPost = posts.filter((p) => p.subtype === POST_TYPE_TEXT)[0];
            let [content, links] = extractLinks(textPost.content);
            if (links.length) {
                let link = links[0];
                textPost.content = content;
                let postId = uuid();
                yield put(
                    create("db.posts", {
                        id: postId,
                        parent: id,
                        subtype: POST_TYPE_LINK,
                        link,
                    })
                );
                yield put(pathAppend(["db.nodes", id, "posts"], postId));
            }
        }
        let appendData = {};
        if (share) appendData.share = share;
        const newTags = yield select((s) => selectNewTags(s, id));
        if (newTags.length) appendData.tags_create = newTags;
        yield put(
            storeOne("db.nodes", id, {
                ...data,
                ...appendData,
            })
        );
        yield put(
            submitOne(context)("db.nodes", id, null, null, null, appendData)
        );
    } catch (e) {
        console.log(e);
    }
}

function* postFiles(id) {
    const stem = yield select((store) => selectEntity(store, "db.nodes", id));
    const posts = yield select((store) =>
        selectById(store, "db.posts", stem.posts || [])
    );
    let album = posts.filter((post) => post.subtype === POST_TYPE_ALBUM)[0];
    if (!album) {
        if (!album)
            album = {
                id: uuid(),
                type: "posts",
                subtype: POST_TYPE_ALBUM,
                parent: id,
            };
        yield put(create("db.posts", album));
        yield put(
            change("db.nodes", stem.id, {
                posts: stem.posts ? stem.posts.concat([album.id]) : [album.id],
            })
        );
    }
    return album.id;
}

function* handlePostUploadMultiple({ context, payload: { id, files, post } }) {
    try {
        const filesId = post ? post : yield postFiles(id);
        const { media: prev } = yield select((s) =>
            selectEntity(s, "db.posts", filesId)
        );
        const next = prev ? prev.slice() : [];
        const cb = function (file) {};
        const ids = files.map((f) => uuid());
        yield put(pathChange(["db.posts", filesId, "media"], next.concat(ids)));
        yield all(
            files.map((file, i) => {
                const fileId = ids[i];
                return put(
                    handleUploadCallback(context)(
                        cb,
                        "db.media",
                        null,
                        file,
                        fileId
                    )
                );
            })
        );
    } catch (e) {
        console.error(e);
    }
}

function* handlePostUpload({ context, payload }) {
    const { id, file } = payload;
    try {
        yield handlePostUploadMultiple({
            context,
            payload: { id, files: [file] },
        });
    } catch (e) {
        console.error(e);
    }
}

function* handlePostReset({ payload: { id } }) {
    try {
        const paths = [`db.nodes/${id}`];
        const isDraft = yield select((store) =>
            selectIsDraft(store, "db.nodes", id)
        );
        const { posts } = yield select((store) =>
            selectEntity(store, "db.nodes", id)
        );
        if (posts) posts.forEach((post) => paths.push(`db.posts/${post}`));
        yield put(resetBatch(paths));
        if (isDraft) {
            yield put(deleteSuccess("db.nodes", id));
        }
        yield put(pushRouteParams({ [ROUTE_POST]: null }));
    } catch (e) {
        console.log(e);
    }
}

function* handleCreateSubmit({ context, payload: { type, id, list } }) {
    try {
        const types = yield select((s) => selectPostContentStructured(s, id));
        if (types[POST_TYPE_POLL] && type && type !== POST_TYPE_POLL) {
            yield put(
                pathDetach(["db.nodes", id, "posts"], types[POST_TYPE_POLL])
            );
        }
        yield put(submitPost(context)(id));
        yield put(listAppend("db.nodes", list, id, -1));
        yield put(createPostReject(id));
    } catch (e) {
        console.error(e);
    }
}

function* handleCreateReject({ payload: { id } }) {
    try {
        yield put(resetPost(id));
        yield put(setFlag(FLAG_NEW_POST_ID, null));
        yield put(setFlag(FLAG_NEW_POST_TYPE, POST_TYPE_TEXT));
    } catch (e) {
        console.error(e);
    }
}

function* handleUpgradeEditor({ payload: { id } }) {
    try {
        const { posts } = yield select((s) => selectEntity(s, "db.nodes", id));
        const postsData = yield select((s) => selectById(s, "db.posts", posts));

        yield put(
            change("db.nodes", id, {
                content: convertPostsToRawState(postsData || []),
            })
        );
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("JOINT.POST.UPLOAD", handlePostUpload);
    yield takeEvery("JOINT.POST.UPLOAD.MULTIPLE", handlePostUploadMultiple);
    yield takeEvery("JOINT.POST.ADD", handleCreatePostContent);
    yield takeEvery("JOINT.POST.CREATE", handleCreatePost);
    yield takeEvery("JOINT.POST.SUBMIT", handleSubmit);
    yield takeEvery("JOINT.POST.RESET", handlePostReset);
    yield takeEvery("JOINT.POST.CREATE.SUBMIT", handleCreateSubmit);
    yield takeEvery("JOINT.POST.CREATE.REJECT", handleCreateReject);
    yield takeEvery("JOINT.POST.UPGRADE_EDITOR", handleUpgradeEditor);
}

import {put} from "redux-saga/effects";
import {post} from "state/actions/api";

export function* handleGiveBadge({context, payload}) {
    const {data} = payload;
    try {
        const {user} = data;
        const url = `joynt/badges/give`;
        yield put(post(context)(url, data, `badge.${user}`));
    } catch (e) {
        console.error(e);
    }
}
import { NODE_TYPE_EXTERNAL, POST_TYPE_LINK } from "joynt/config/index";
import coreConfig from "config/validation";

const config = {
    ...coreConfig,
    "ui.forms/welcome": {
        name: {
            required: true,
            min: 4,
        },
        /*contexts: {
            required: true,
            minCount: 1,
            validType: 'ui.contexts'
        }*/
    },
    /*'ui.contexts': {
        name: {
            required: true
        }
    }*/
    "db.identities": {
        name: {
            required: true,
            min: 4,
        },
    },
    "db.nodes": {
        name: {
            required: true,
            min: 4,
            max: 168,
            format: "plain-text",
        },
        workspace_abbr: {
            max: 3,
            format: "plain-text",
        },
        parent: {
            required: ({ subworkspace }) => !!subworkspace,
        },
    },
    "ui.forms/invite": {
        emails: {
            required: true,
            format: "email-list",
        },
    },
};

config[`db.posts/${POST_TYPE_LINK}`] = {
    link: {
        required: true,
        format: "url",
    },
};

config[`db.nodes/${NODE_TYPE_EXTERNAL}`] = {
    ...config["db.nodes"],
    external_url: {
        required: true,
        format: "url",
    },
};

export default config;

import { select, takeEvery, put } from "redux-saga/effects";
import { ROUTE_PAGE } from "joynt/config";
import { create, createCallback } from "state/actions/create";
import { pushRouteParams } from "state/actions/router";
import { selectIsDraft, selectList } from "state/selectors/data";
import { createNode, createWorkspace } from "joynt/factory/nodes/create";
import { nodeSubmitData } from "joynt/factory/nodes/submit";
import { submitOneCallback } from "state/actions/api";
import { denormalize } from "state/util";
import { confirmPrompt } from "util/saga/feedback";
import {
    selectFollowupConfirms,
    selectRequiredSubmitConfirmation,
} from "joynt/state/selectors/joint";

function* handleCreateWorkspace({ payload }) {
    try {
        const { parent, list, data } = payload;
        const workspaceData = yield select((s) =>
            createWorkspace(s, parent, data)
        );
        const { id } = workspaceData;
        yield put(create("db.nodes", workspaceData, list, -1));
        yield put(pushRouteParams({ [ROUTE_PAGE]: `edit.${id}` }));
    } catch (e) {
        console.error(e);
    }
}

function* handleCreateNode({ payload, callback }) {
    try {
        const type = "db.nodes";
        const { parent, list, data } = payload;
        const nodeData = yield select((s) => createNode(s, parent, data));
        const cb = callback ? () => callback(nodeData.id) : null;
        yield put(createCallback(cb, type, nodeData, list));
    } catch (e) {
        console.error(e);
    }
}

function* handleSubmitNode({ context, payload, callback }) {
    const { id, data: addData } = payload;
    const type = "db.nodes";

    try {
        const data = yield select((s) => denormalize(s, type, id));
        const isDraft = yield select((s) => selectIsDraft(s, type, id));

        /**
         * Check if there is confirm dialog required
         * before submitting node to server (based on node data)
         */
        const requiredConfirmType = yield select((s) =>
            selectRequiredSubmitConfirmation(s, type, id)
        );

        let confirmResult = null;

        /** Display confirm dialog and capture the response **/
        if (requiredConfirmType) {
            confirmResult = yield confirmPrompt({
                confirmType: requiredConfirmType,
                id,
                type,
            });
            if (!confirmResult) return;
        }

        /**
         * Merge data from possible sources
         */
        const dataSources = [
            //node data in store
            data,
            //node profile data
            isDraft ? nodeSubmitData(data) : {},
            //data passed in submit action call
            addData || {},
            //data passed in confirm action call
            typeof confirmResult === "object" ? confirmResult : {},
        ];

        let nodeData = Object.assign(...dataSources);

        /**
         * Add changes in roles to request
         */
        const rolesList = isDraft
            ? `node-roles.${id}.edit`
            : `node-roles.${id}`;
        const allRoles = yield select((s) =>
            selectList(s, "app.node-roles", rolesList)
        );
        const roles = allRoles.filter((role) => !!role.action);
        if (roles.length) nodeData.roles = roles;

        const cb = (arg) => {
            console.log(`Node submit cb arg`, arg);
            if (callback) callback(isDraft);
        };

        yield put(
            submitOneCallback(context)(cb, type, id, null, null, null, nodeData)
        );

        /**
         * Display followup dialog during request
         * and after its completed
         */
        const followup = yield select((s) =>
            selectFollowupConfirms(s, type, id, nodeData)
        );

        if (followup) {
            yield confirmPrompt({
                id: id,
                confirmType: followup,
            });
        }
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("JOINT.WORKSPACE.CREATE", handleCreateWorkspace);
    yield takeEvery("JOINT.NODE.CREATE", handleCreateNode);
    yield takeEvery("JOINT.NODE.SUBMIT", handleSubmitNode);
}

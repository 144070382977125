import {takeEvery, put} from "redux-saga/effects";
import {request} from "util/api/client";
import {patchOne} from "state/actions/data";
import {pending} from 'state/actions/ui';

function* handleReaction({context, payload}) {
    const {type, id, reaction_type} = payload;
    const pendingId = `${id}.reaction`;
    try {
        const resource = type.split('.')[1];
        const url = `joynt/${resource}/${id}/reaction`;
        yield put(pending(pendingId, true));
        const {data: {data: response}} = yield request({
            method: 'post',
            context,
            url,
            data: {reaction_type}
        });
        yield put(patchOne(type, id, {reactions: response.reactions}));
        yield put(pending(pendingId, false));
    } catch (e) {
        yield put(pending(pendingId, false));
        console.log(e);
    }
}

export default function*() {
    yield takeEvery('REACTION.TOGGLE', handleReaction);
}
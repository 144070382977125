import { updateIn, deleteIn, setIn, getIn } from "lodash-redux-immutability";
import { createReducer } from "../helpers/create-reducer";
import { normalize } from "state/util";
import { FILTER_NS } from "config/list-aliases";
import { NS_VALIDATION } from "state/sagas/validation";

const actions = {
    "DATA.CHANGE": (state, { payload }) => {
        const { type, id, data } = payload;
        let keys = Object.keys(data);
        let path = [type, id];
        let next = { ...data };
        if (keys.length === 1) {
            let keyPath = keys[0].split(".");
            if (keyPath.length > 1) {
                let prev = {
                    [keyPath[0]]: getIn(state, [...path, keyPath[0]]) || {},
                };
                next = setIn(prev, keyPath, data[keys[0]]);
            }
        }
        return updateIn(state, path, next);
    },
    "DATA.RESET": (state, { payload }) => {
        const { type, id } = payload;
        return deleteIn(state, [type, id]);
    },
    "DATA.RESET.BATCH": (state, { payload }) => {
        const { paths } = payload;
        let next = { ...state };
        paths.forEach((path) => {
            let [type, id] = path.split("/");
            next = deleteIn(next, [type, id]);
        });
        return next;
    },
    "DATA.STORE.BATCH": (state, { payload }) => {
        const { data } = payload;
        const types = Object.keys(data);
        let next = { ...state };
        types.forEach((type) => {
            if (next[type] && data[type].forEach) {
                data[type].forEach((entity) => {
                    next = deleteIn(next, [type, entity.id]);
                });
            }
        });
        return next;
    },
    "DATA.LIST.CREATE": (state, { payload }) => {
        const { type, item } = payload;
        return normalize(state, type, [item]);
    },
    "DATA.CREATE": (state, { payload }) => {
        const { type, item } = payload;
        return normalize(state, type, [item]);
    },
    "DELETE.SUCCESS": (state, { payload }) => {
        const { type, id } = payload;
        return deleteIn(state, [type, id]);
    },
    "DATA.LIST.RESET": (state, { payload }) => {
        const { filter } = payload;
        return setIn(state, [FILTER_NS, filter, "page"], 1);
    },
    "DATA.PATH.CHANGE": (state, { payload }) => {
        const { path, value } = payload;
        return setIn(state, path, value);
    },
    "DATA.PATH.UNSET": (state, {payload}) => {
        const { path } = payload;
        return deleteIn(state, path);
    },
    "DATA.REJECT": (state, { payload }) => {
        const { type, id } = payload;
        let next = deleteIn(state, [type, id]);
        return deleteIn(next, [NS_VALIDATION, [type, id].join("/")]);
    },
    "DATA.RESET_ALL": (state) => {
        return {};
    },
};

export default createReducer({}, actions);

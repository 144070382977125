import { takeEvery, put, select } from "redux-saga/effects";
import {patchOne, pathChange, storeList, storeOne} from "state/actions/data";
import { selectById, selectEntity } from "state/selectors/data";
import { fetchOne } from "state/actions/api";
import { selectRouteParam } from "state/selectors/router";
import {
    FLAG_CHAT_THREAD,
    FLAG_SESSION_ID,
    FLAG_VISIBLE_CHATS,
    NODE_TYPE_EVENT,
    ROUTE_CHANNEL,
    ROUTE_ID,
    ROUTE_THREAD,
    ROUTE_VIEW,
} from "joynt/config";
import { pushMessage } from "joynt/state/actions/messages";
import {
    hideNotification,
    notify,
    setFlag,
    showNotification,
} from "state/actions/ui";
import { listAppend, listRemove, listUpdated } from "state/actions/list";
import { selectFlag } from "state/selectors/ui";
import {
    handleLeaveMeeting,
    handleSessionStarted,
    handleSessionEnded,
} from "joynt/state/sagas/meetings";
import {
    isSessionChat,
    selectEdges,
    selectSessionChat,
} from "joynt/state/selectors/joint";
import { hideToasts, showSnackbar } from "joynt/notifications/config";
import { selectCurrentUserId } from "state/selectors/app";
import { bootstrap } from "joynt/state/actions";
import { deleteSuccess } from "state/actions/delete";
import { segmentTransition } from "joynt/components/SessionFlow/actions";

const activityHandlers = {
    "current-event": handleCurrentEventActivity,
    joined: handleJoinedActivity,
    left: handleLeftActivity,
    invite: handleInviteActivity,
    "reaction.add": handleReaction,
    "reaction.revoke": handleReaction,
    "session.start": handleSessionStarted,
    "session.end": handleSessionEnded,
    "post-published": handlePostPublished,
    "witness.time-available": handleWitnessTimeAvailable,
    "message": handleMessage
};

function* handleActivity({ context, payload }) {
    const { event } = payload;
    try {
        const list = ["db.activity", event.parent].join(".");

        yield put(storeList("db.activity", list, [event], null, true));

        const { activity_type } = event;
        const handler = activityHandlers[activity_type];

        if (!handler) {
            console.log(`No handler defined for ${activity_type}`, event);
            return;
        }

        yield handler({ context, payload: event });
    } catch (e) {
        throw e;
    }
}

function* handleMessage({ payload }) {
    try {
        console.log('Message activity', payload);
        const user = yield select(s => selectCurrentUserId(s));
        const {parent} = payload;
        const path = ['db.notification-stats', user, 'all', 'nodes', parent]
        yield put(pathChange(path, 1));
    } catch (e) {
        console.error(e);
    }
}

function* handleReaction({ payload }) {
    try {
        const { payload: reaction } = payload;
        const { type, id, reactions } = reaction;
        yield put(patchOne(["db", type].join("."), id, { reactions }));
    } catch (e) {
        throw e;
    }
}

function* handleJoinedActivity({ payload }) {
    try {
        const { parent, identity } = payload;
        const list = `participants.${parent}`;
        yield put(listAppend("db.identities", list, identity));
    } catch (e) {
        console.log(e);
    }
}

function* handleLeftActivity({ context, payload }) {
    try {
        const { parent, identity } = payload;
        const list = `participants.${parent}`;
        yield put(listRemove("db.identities", list, identity));
        const user = yield select((s) => selectCurrentUserId(s));
        const { created_by } = yield select((s) =>
            selectEntity(s, "db.identities", identity)
        );
        const id = yield select((s) => selectRouteParam(s, "id"));
        if (user === created_by) {
            yield handleLeaveMeeting();
            yield put(deleteSuccess("db.nodes", id));
            yield put(bootstrap(context)(id));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleInviteActivity({ context, payload }) {
    try {
        const { parent } = payload;
        const id = yield select((s) => selectRouteParam(s, "id"));
        if (id === parent) {
            yield put(fetchOne(context)("db.nodes", id));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleCurrentEventActivity({ context, payload }) {
    try {
        const { parent, resource_id } = payload;
        const node = yield select((s) => selectEntity(s, "db.nodes", parent));

        if (node.current_event !== resource_id) {
            yield put(fetchOne(context)("db.nodes", parent));
        }
    } catch (e) {
        throw e;
    }
}

function* handlePushEntity({ context, payload }) {
    try {
        const { type, id, data } = payload;
        if (type === "messages") {
            if (data.is_deleted) {
                yield put(storeOne("db.messages", data.id, data));
            } else {
                yield put(pushMessage(data, `thread.${data.parent}`));
            }
            return;
        }
        if (type === "nodes") {
            if (data.is_deleted) {
                yield put(deleteSuccess(`db.${type}`, id));
                return;
            }
        }
        let nsType = ["db", type].join(".");
        let prevData = yield select((store) => selectEntity(store, nsType, id));

        let edges = prevData.edges_ref ? { ...prevData.edges_ref } : {};
        let nextEdges = data.edges_ref ? data.edges_ref : {};

        let next = {
            ...prevData,
            ...data,
            edges_ref: { ...edges, ...nextEdges },
        };

        yield put(storeOne(nsType, id, next));

        if (next.subtype === NODE_TYPE_EVENT && !next.workspace) {
            const workspace = yield select((s) =>
                selectRouteParam(s, ROUTE_ID)
            );
            const workspaceList = `db.nodes.${workspace}.channels`;
            if (next.event_state === "live") {
                yield put(listAppend("db.nodes", workspaceList, next.id));
            } else {
                console.log(`Remove ${next.id} from ${workspaceList}`);
                yield put(listRemove("db.nodes", workspaceList, next.id));
            }
        }

        if (prevData.session_flow_segment !== next.session_flow_segment) {
            yield put(
                segmentTransition(context)(
                    id,
                    prevData.session_flow_segment,
                    next.session_flow_segment
                )
            );
        }

        if (next.event_state === "ended") {
            const currentSession = yield select((s) =>
                selectFlag(s, "meetingSessionId")
            );
            if (currentSession === next.id) {
                yield handleLeaveMeeting();
            }
        }
    } catch (e) {
        throw e;
    }
}

function* handlePostPublished({ payload }) {
    try {
        const { parent, created_at } = payload;
        const { workspace } = yield select((store) =>
            selectEdges(store, parent)
        );
        yield put(
            listUpdated("db.nodes", ["db.posts", parent].join("."), created_at)
        );
        yield put(
            listUpdated(
                "db.nodes",
                ["db.posts", workspace].join("."),
                created_at
            )
        );
        yield handleMessage({ payload });
    } catch (e) {
        throw e;
    }
}

function* handleMessageNotification({ payload }) {
    try {
        const { data } = payload.event;
        const {
            notification_type: type,
            notification_priority: priority,
            activity,
        } = data;
        const { parent } = activity;
        const chatThread = yield select((store) =>
            selectFlag(store, FLAG_CHAT_THREAD)
        );
        const routeChannel = yield select((store) =>
            selectRouteParam(store, ROUTE_CHANNEL)
        );
        const routeThread = yield select((store) =>
            selectRouteParam(store, ROUTE_THREAD)
        );

        const routeView = yield select((store) =>
            selectRouteParam(store, ROUTE_VIEW)
        );

        const currentSessionChat = yield select((store) =>
            selectSessionChat(store)
        );
        const isMobile = yield select((s) => s?.ui?.screenSize?.isMobile);

        const popupNotificationTypes = [
            "message.dm",
            "message.mention",
            "message.mention.all",
        ];

        if (priority === "low") return;

        /** Don't do anything for session chat for active sessions **/
        if (currentSessionChat && currentSessionChat === parent) {
            if (type === "message.share") {
                yield handleNotificationTag(data.id);
            }
            return;
        }

        /** Show chat popup **/
        if (
            popupNotificationTypes.indexOf(type) > -1 &&
            routeView !== "inbox" &&
            !chatThread &&
            routeChannel !== parent &&
            !isMobile
        ) {
            yield put(setFlag(FLAG_CHAT_THREAD, parent));
            return;
        }

        const isSeeingChat = yield select((s) => {
            const visibleChats = selectFlag(s, FLAG_VISIBLE_CHATS) || [];
            return visibleChats.includes(parent);
        });

        if (isSeeingChat) return;

        yield handleNotificationTag(data.id);
    } catch (e) {
        throw e;
    }
}

function* handleNotificationTag(id) {
    const current = yield select((s) => s.ui.notifications) || [];
    const list = yield select((s) =>
        selectById(s, "db.notifications", current)
    );
    const data = yield select((s) => selectEntity(s, "db.notifications", id));
    const { tag } = data;
    const previousNotification = list.filter((n) => n.tag === tag);
    if (previousNotification.length) {
        let prev = previousNotification[0];
        let prevIdentities = prev.identities || [];
        let nextIdentities = prevIdentities.slice();
        data.count = prev.count ? prev.count + 1 : 2;
        if (nextIdentities.indexOf(data.identity) === -1) {
            nextIdentities.push(data.identity);
        }
        data.identities = nextIdentities;
        yield put(storeOne("db.notifications", id, data));
        yield put(hideNotification(prev.id));
        yield put(showNotification(id));
    } else {
        yield put(showNotification(id));
    }
}

function* handleNotification({ payload }) {
    try {
        const { data } = payload.event;
        if (data) {
            const {
                activity: { activity_type: type },
            } = data;
            console.log(`Notification ${type}`, data);
            yield put(storeOne("db.notifications", data.id, data));
            if (hideToasts.indexOf(type) === -1) {
                if (type === "message") {
                    yield handleMessageNotification({ payload });
                } else {
                    yield handleNotificationTag(data.id);
                }
            }
            if (showSnackbar.indexOf(type) > -1) {
                yield put(notify(type, "info", data.id));
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleWitnessTimeAvailable({ payload: data }) {
    try {
        const node = {
            id: data.resource_id,
            type: "nodes",
            event_start: data.payload.event_start,
        };
        const list = `witness.${data.parent}`;
        yield put(storeOne("db.nodes", node.id, node));
        yield put(listAppend("db.nodes", list, node.id));
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("ACTIVITY.PUSH", handleActivity);
    yield takeEvery("JOINT.PUSH_ENTITY", handlePushEntity);
    yield takeEvery("JOINT.NOTIFICATION", handleNotification);
}

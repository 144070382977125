import { put, select } from "redux-saga/effects";
import { pathAppend, pathChange } from "state/actions/data";
import { selectEntity } from "state/selectors/data";

export function* handleAssignNodeRole(action) {
    const {
        payload: { id, role },
    } = action;

    const type = "app.node-roles";
    const path = [type, id];
    const { action: roleAction } = yield select((store) =>
        selectEntity(store, type, id)
    );

    yield put(pathAppend(path.concat(["roles"]), role));

    if (!roleAction) {
        yield put(pathChange(path.concat(["action"]), "change"));
    }
}

import {NODE_TYPE_EVENT, SESSION_POLICY_DEFAULT, SESSION_POLICY_MEETING} from "joynt/config";
import {selectEdges, selectRootNodeId, selectUserNodeIdentity} from "joynt/state/selectors/joint";
import uuid from "uuid/v4";
import {createHourRange} from "joynt/components/DateTimePicker/util";
import {sessionTypes} from "joynt/config/sessions";

const workspaceTemplate = {
    event_session_policy: SESSION_POLICY_DEFAULT,
    workspace: true,
    public: false,
    published: false,
    default_view: 'workspace',
    edges_ref: {}
};

export function createWorkspace(store, parent, data = {}) {
    const id = uuid();

    const identity = selectUserNodeIdentity(store, parent);
    const root = selectRootNodeId(store);

    const edges_ref = Object.assign(
        {
            parent,
            parent_workspace: parent !== root ? parent : null,
            workspace: id
        },
        workspaceTemplate.edges_ref || {},
        data.edges_ref || {},
        {draft: true}
    )

    return {
        ...workspaceTemplate,
        id,
        parent,
        identity,
        ...data,
        edges_ref
    }
}

function defaultData(data) {
    if (data.subtype === NODE_TYPE_EVENT) {
        let defaultTime = !data.workspace
            ? createHourRange(null, 1)
            : [];
        let sessionType = data.session_type || 'stage';
        let sessionTypeData = sessionTypes[sessionType]?.data || {}
        return {
            event_session_policy: SESSION_POLICY_MEETING,
            event_start: defaultTime[0],
            event_end: defaultTime[1],
            features: {chat: true},
            ...data,
            ...sessionTypeData
        }
    }
    return data;
}

export function createNode(store, parent, data = {}) {
    const id = uuid();

    const identity = selectUserNodeIdentity(store, parent);
    const {workspace} = selectEdges(store, parent);

    return defaultData({
        id,
        subtype: null,
        parent,
        identity,
        public: true,
        published: true,
        edges_ref: {
            parent,
            workspace,
            draft: true
        },
        ...data
    });
}
import React from "react";

import { Route } from "react-router";
import AuthCallback from "components/auth/AuthCallback";
import EntrypointNoAuth from "joynt/EntrypointNoAuth";
import { DefaultLogin } from "components/layouts/LoginLayout";

export default function (props = {}) {
    const LoginLayout = props.LoginLayout || DefaultLogin;

    return [
        <Route key="auth" exact path="/auth">
            <AuthCallback />
        </Route>,
        <Route key="login" exact path="/login">
            <EntrypointNoAuth>
                <LoginLayout view={"login"} title={"Login"} />
            </EntrypointNoAuth>
        </Route>,
        <Route key="signup" exact path="/signup">
            <EntrypointNoAuth>
                <LoginLayout view={"signup"} title={"Signup"} />
            </EntrypointNoAuth>
        </Route>,
        <Route key="password-reset" exact path="/password-reset">
            <EntrypointNoAuth>
                <LoginLayout view={"password-reset"} title={"Reset password"} />
            </EntrypointNoAuth>
        </Route>,
    ];
}

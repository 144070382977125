import { LIST_FILTER, FILTER_NS } from "config/list-aliases";

import {takeEvery, put, select } from "redux-saga/effects";

import { selectFilteredList } from "state/selectors/filters";
import { storeListIds } from 'state/actions/data';

function* applyListFilter(action) {
    try {
        const { payload } = action;
        const { type, id } = payload;
        if (!type || !id) return;
        const [entityType, listId] = id.split('/');
        if (type === FILTER_NS) {
            yield updateFilter({payload: {type: entityType, id: listId}});
        }
    } catch (e) {
        throw e;
    }
}

function* updateFilter({payload}) {
    try {
        const { type, id } = payload;
        const filterId = [type,id].join('/');
        const data = yield select(store => selectFilteredList(store, type, id, filterId));
        yield put(storeListIds(type, [id, LIST_FILTER].join('/'), data));
    } catch (e) {
        console.log(e);
    }
}

export default function*() {
    yield takeEvery('DATA.CHANGE', applyListFilter);
    yield takeEvery('FILTERS.UPDATE', updateFilter);
}
import { put, select } from "redux-saga/effects";
import { pending, setFlag } from "state/actions/ui";
import { request } from "util/api/client";
import { storeOne } from "state/actions/data";
import { handleEndSession } from "joynt/state/sagas/meetings";
import {
    selectFlowSegment,
    selectNodeFlowPath,
} from "joynt/components/SessionFlow/selectors";
import { confirm } from "util/saga/feedback";

export function* handleSetSessionFlowSegment({ context, payload }) {
    const { session, segment } = payload;
    const id = `${session}.flow`;
    const flagId = `session.${session}.flowSegment`;
    const flowPath = yield select((s) => selectNodeFlowPath(s, session));
    const [flowId] = flowPath.split(".");
    const config = yield select((s) => selectFlowSegment(s, flowId, segment));

    try {
        const url = `joynt/nodes/${session}`;

        let confirmed = true;

        if (config.confirm) {
            confirmed = yield confirm(config.confirm);
        }

        if (!confirmed) return;

        if (segment === "end") {
            yield put(pending(id, true));
            yield handleEndSession({ context, payload: { id: session } });
            yield put(setFlag(flagId, null));
            yield put(pending(id, false));
            return;
        }
        const requestData = {
            id: session,
            session_flow_segment: segment,
            session_flow_segment_started: new Date().getTime() / 1000,
        };
        yield put(pending(id, true));
        const {
            data: { data: responseData },
        } = yield request({
            url,
            method: "post",
            data: requestData,
            context,
        });
        yield put(storeOne("db.nodes", session, responseData));
        yield put(setFlag(flagId, null));
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
        console.error(e);
    }
}

import {select, put, takeEvery} from 'redux-saga/effects';
import {reject} from "state/actions/data";
import {resetPost} from "joynt/state/actions/posts";
import {selectFlag} from "state/selectors/ui";
import {FLAG_NEW_POST_ID} from "joynt/config";

let PREV_LOCATION = null;

function isExitingEditRoute(key, prev, next) {
    return prev[key] && prev[key].indexOf('edit.') === 0 && prev[key] !== next[key];
}

function* handleRouteChange(action) {
    try {
        const current = yield select(s => s.router);
        if (PREV_LOCATION) {
            const prevQuery = PREV_LOCATION.query;
            const nextQuery = current.query;

            if (isExitingEditRoute('post', prevQuery, nextQuery)) {
                let id = prevQuery.post.split('.')[1];
                let newPostId = yield select(s => selectFlag(s, FLAG_NEW_POST_ID));
                if (id !== newPostId) {
                    yield put(resetPost(id));
                }
            }
            if (isExitingEditRoute('page', prevQuery, nextQuery)) {
                let id = prevQuery.page.split('.')[1];
                yield put(reject("db.nodes", id));
            }
        }
        PREV_LOCATION = current;
    } catch (e) {

    }
}

export default function*() {
    yield takeEvery('@@router/LOCATION_CHANGE', handleRouteChange);
}
import {
    NODE_TYPE_CHAT,
    NODE_TYPE_EVENT,
    NODE_TYPE_FEED,
    NODE_TYPE_SESSIONS,
    NODE_TYPE_SPACE,
    NODE_TYPE_THREADS,
} from "joynt/config";
import { SESSION_TYPE_NETWORKING_TABLE } from "joynt/config/sessions";

const profiles = {
    session: {
        child_nodes: [
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: true,
                name: "$parent Chat",
            },
            {
                subtype: NODE_TYPE_FEED,
                public: true,
                published: true,
                name: "$parent Feed",
            },
            {
                subtype: NODE_TYPE_THREADS,
                public: true,
                published: true,
                name: "$parent Q&A",
            },
            {
                subtype: NODE_TYPE_SESSIONS,
                public: true,
                published: true,
                name: "$parent Breakout Rooms",
            },
        ],
    },
    event: {
        child_nodes: [
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: true,
                name: "$parent Chat",
            },
            {
                subtype: NODE_TYPE_FEED,
                public: true,
                published: true,
                name: "$parent Feed",
            },
            {
                subtype: NODE_TYPE_THREADS,
                public: true,
                published: true,
                name: "$parent Q&A",
            },
            {
                subtype: NODE_TYPE_SESSIONS,
                public: true,
                published: true,
                name: "$parent Breakout Rooms",
            },
        ],
    },
    workspace: {
        child_nodes: [
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: true,
                name: "#general",
            },
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: true,
                name: "#random",
            },
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: false,
                name: "#admin",
            },
        ],
    },
    room: {
        child_nodes: [
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: true,
                name: "$parent Chat",
            },
            {
                subtype: NODE_TYPE_FEED,
                public: true,
                published: true,
                name: "$parent Feed",
            },
        ],
    },
    networkingTable: {
        child_nodes: [
            {
                subtype: NODE_TYPE_CHAT,
                public: true,
                published: true,
                name: "$parent",
            },
        ],
    },
};

const featureNodes = {
    chat: {
        subtype: NODE_TYPE_CHAT,
        public: true,
        published: true,
        name: "$parent Chat",
    },
    files: {
        subtype: NODE_TYPE_FEED,
        public: true,
        published: true,
        name: "$parent Feed",
    },
    qa: {
        subtype: NODE_TYPE_THREADS,
        public: true,
        published: true,
        name: "$parent Q&A",
    },
    rooms: {
        subtype: NODE_TYPE_SESSIONS,
        public: true,
        published: true,
        name: "$parent Breakout Rooms",
    },
};

function addFeatures(featuresCfg) {
    const ids = Object.keys(featuresCfg);
    const nodes = [];
    ids.forEach((id) => {
        if (featuresCfg[id]) nodes.push(featureNodes[id]);
    });
    return nodes;
}

function selectNodeProfile(data) {
    const { subtype, workspace, features, session_type } = data;

    if (session_type === SESSION_TYPE_NETWORKING_TABLE)
        return profiles.networkingTable;

    if (subtype === NODE_TYPE_EVENT && workspace) return profiles.event;
    if (subtype === NODE_TYPE_SPACE && workspace) return profiles.workspace;

    if (subtype === NODE_TYPE_EVENT)
        return {
            child_nodes: addFeatures(features),
        };

    if (subtype === NODE_TYPE_SPACE) return profiles.room;

    return {};
}

export function nodeSubmitData(data) {
    return {
        ...data,
        ...selectNodeProfile(data),
    };
}

import React from 'react';
//import PropTypes from 'prop-types';
import AuthForm from "components/auth/AuthForm";
import 'css/login.css';

export function DefaultLogin(props) {
    return <div className={'console-login'}>
        <div className={'login-splash primary'}>
        </div>
        <div className={'relative login-content'}>
            <AuthForm {...props}/>
        </div>
    </div>;
}
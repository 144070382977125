import {takeEvery} from "redux-saga/effects";
import {handleGiveBadge} from "./handleGiveBadge";
import {handleEndSession} from "./handleEndSession";
import {handleSwitchToSolo} from "./handleSwitchToSolo";
import {handleStartSession} from "./handleStartSession";

export default function*() {
    yield takeEvery('WITNESS.BADGE', handleGiveBadge);
    yield takeEvery('WITNESS.SESSION.START', handleStartSession);
    yield takeEvery('WITNESS.SESSION.END', handleEndSession);
    yield takeEvery('WITNESS.SESSION.SOLO', handleSwitchToSolo);
}
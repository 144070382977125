import { takeEvery, put, select, race, take } from "redux-saga/effects";
import { notify, pending, setFlag, setFlags } from "state/actions/ui";
import { change, storeOne } from "state/actions/data";
import { request } from "util/api/client";
import { submitOne } from "state/actions/api";
import { selectFlag } from "state/selectors/ui";
import { sessionPresenceClear } from "joynt/state/actions/presence";
import { confirm } from "util/saga/feedback";
import { selectEntity } from "state/selectors/data";
import { selectRouteParam } from "state/selectors/router";
import { joinMeeting } from "joynt/state/actions/meetings";
import { pushRouteParams } from "state/actions/router";
import {
    selectEventRole,
    selectPresentIdentities,
} from "joynt/state/selectors/joint";
import { nodeLink } from "joynt/state/actions";
import { listAppend } from "state/actions/list";
import { DEFAULT_NETWORKING_TABLE_LIMIT } from "joynt/config";
import AgoraRTC from "agora-rtc-sdk-ng";

import {
    EVENT_ROLE_RECORDING,
    FLAG_SESSION_CONFIRM,
    FLAG_SESSION_ID,
    FLAG_SESSION_PENDING,
    FLAG_SESSION_RAISED_HAND,
    NODE_TYPE_EVENT,
    NODE_TYPE_SESSIONS,
    STAGE_VIEW,
} from "joynt/config";

import { createNode } from "joynt/factory/nodes/create";
import { nodeSubmitData } from "joynt/factory/nodes/submit";
import { handleResponse } from "state/sagas/api";

function* handleAutoJoin({ payload }) {
    const { id } = payload;
    try {
        let confirmedFlagId = `meeting.settingsConfirmed`;
        let liveIntentFlagId = `meeting.${id}.liveIntent`;
        yield put(
            setFlags({
                [confirmedFlagId]: true,
                [liveIntentFlagId]: true,
                meetingSessionId: id,
            })
        );
    } catch (e) {
        console.log(e);
    }
}

function* canJoinSession(id) {
    const { session_type } = yield select((s) =>
        selectEntity(s, "db.nodes", id)
    );
    if (session_type !== "networking-table") return true;

    const presence = yield select((s) =>
        selectPresentIdentities(s, id, "sessions")
    );

    return presence.length < DEFAULT_NETWORKING_TABLE_LIMIT;
}

function* handleJoinMeeting({ context, payload }) {
    const { id, forced } = payload;

    if (id && !AgoraRTC.checkSystemRequirements()) {
        if (
            yield confirm({
                message: "Your browser is not supported",
                description:
                    "We recommend using Safari or Chrome, you may encounter issues otherwise.",
                cancelText: "Try anyway",
                confirmText: "Cancel",
            })
        ) {
            return;
        }
    }

    try {
        if (!id) {
            yield handleLeaveMeeting();
            return;
        }
        let previousId = yield select((s) => selectFlag(s, "meetingSessionId"));
        const isMobile = yield select((s) => s?.ui?.screenSize?.isMobile);

        /** Already joined **/
        if (previousId === id) return;

        if (previousId && !forced) {
            let confirmed = yield confirm({
                message: "Switch sessions?",
                description: "You will leave current session",
                cancelText: "Stay",
                confirmText: "Join",
            });
            if (!confirmed) return;
        }

        const canJoin = yield canJoinSession(id);

        if (!canJoin) {
            yield confirm({
                message: "Table full",
                description:
                    "All spots are taken. Please choose another table.",
                cancelText: false,
                confirmText: "Okay",
            });
            return;
        }

        //yield put(setFlag(FLAG_SESSION_FLOATING, false));
        yield put(setFlag(FLAG_SESSION_PENDING, id));
        yield put(
            setFlag(`meeting.connection.${id}`, {
                connectionState: { currentState: "CONNECTING" },
                loading: true,
            })
        );

        if (previousId && forced !== false) {
            yield handleLeaveMeeting();
        }

        if (forced !== false) {
            yield put(nodeLink(context)(id, null, STAGE_VIEW));
        }

        const sessionData = yield select((s) =>
            selectEntity(s, "db.nodes", id)
        );

        const access = yield select((s) =>
            selectEntity(s, "db.session-access", id)
        );

        if (sessionData.session_type !== "networking-table" || isMobile) {
            yield put(setFlag(`layout.collapse.${id}`, true));
        } else {
            yield put(setFlag(`layout.collapse.${id}`, false));
        }

        if (!access.id || sessionData.subtype === NODE_TYPE_EVENT) {
            const url = `joynt/nodes/${id}/join`;
            yield put(pending(id, true));
            const response = yield request({ url, context });
            yield put(pending(id, false));
            const data = response.data.data;
            yield put(storeOne("db.session-access", data.id, data));
        }

        if (previousId) yield put(sessionPresenceClear(previousId));

        yield put(
            setFlags({
                [FLAG_SESSION_ID]: id,
                [FLAG_SESSION_PENDING]: null,
            })
        );
    } catch (e) {
        yield put(pending(id, false));
        yield put(
            setFlags({
                [FLAG_SESSION_ID]: null,
                [FLAG_SESSION_PENDING]: null,
            })
        );
        console.log(e);
    }
}

export function* handleLeaveMeeting() {
    try {
        yield put(setFlag("meetingSessionId", null));
    } catch (e) {
        console.log(e);
    }
}

function* handleSetCurrentEvent({ context, payload }) {
    try {
        const { parent, id } = payload;
        const data = { current_event: id };
        yield put(change("db.nodes", parent, data));
        yield put(submitOne(context)("db.nodes", parent));
    } catch (e) {
        console.log(e);
    }
}

export function* handleEndSession({ context, payload }) {
    try {
        const { id } = payload;
        if (
            yield confirm({
                message: "Are you sure?",
                description: "All participants will be disconnected",
                cancelText: "Cancel",
                confirmText: "End session",
            })
        ) {
            yield put(pending(id, true));
            yield request({ url: `joynt/nodes/${id}/end`, context });
            yield put(pending(id, false));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleResetSession({ context, payload }) {
    try {
        const { id } = payload;
        yield put(pending(id, true));
        yield request({ url: `joynt/nodes/${id}/reset`, context });
        yield put(pending(id, false));
    } catch (e) {
        console.error(e);
    }
}

function* handleStartNextSession({ context, payload }) {
    try {
        const { id } = payload;
        const { current_event } = yield select((s) =>
            selectEntity(s, "db.nodes", id)
        );
        const currentSession = yield select((s) =>
            selectFlag(s, FLAG_SESSION_ID)
        );
        let confirmed = false;
        if (!current_event) {
            confirmed = true;
        } else {
            confirmed = yield confirm({
                message: "Are you sure?",
                description: "Current session will be ended",
                cancelText: "Cancel",
                confirmText: "Start next session",
            });
        }
        if (confirmed) {
            yield put(pending(id, true));
            yield request({ url: `joynt/nodes/${id}/resume`, context });
            if (currentSession !== id)
                yield handleJoinMeeting({
                    context,
                    payload: { id, force: true },
                });
            yield put(pending(id, false));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleStartRecording({ context, payload: { id } }) {
    try {
        yield put(pending(id, true));
        const url = `joynt/nodes/${id}/record/start`;
        yield request({ url, context });
        yield put(notify("Recording started", "success"));
        yield put(pending(id, false));
    } catch (e) {
        yield put(notify("Unable to start recording", "error"));
        yield put(pending(id, false));
        console.log(e);
    }
}

function* handleStopRecording({ context, payload: { id } }) {
    try {
        yield put(pending(id, true));
        const url = `joynt/nodes/${id}/record/stop`;
        yield request({ url, context });
        yield put(notify("Recording ended", "info"));
        yield put(pending(id, false));
    } catch (e) {
        yield put(notify("Unable to stop recording", "error"));
        yield put(pending(id, false));
        console.log(e);
    }
}

export function* handleSessionStarted({ context, payload }) {
    try {
        const { parent: id } = payload;
        const session = yield select((s) => selectFlag(s, "meetingSessionId"));
        const workspace = yield select((s) => selectRouteParam(s, "id"));
        const node = yield select((s) =>
            selectEntity(s, "db.nodes", workspace)
        );
        const data = yield select((s) => selectEntity(s, "db.nodes", id));
        const stage = data.edges_ref.stage;

        const pullOnStage =
            data.session_type === "witness" ||
            (node.current_event === id && session !== workspace);

        if (pullOnStage) {
            let confirmed = yield confirm({
                message: "Session just started on main stage",
                description: "Do you want to join?",
                cancelText: "Later",
                confirmText: "Join",
            });
            if (confirmed) {
                yield put(joinMeeting(context)(stage, true));
                yield put(nodeLink(context)(stage, null, STAGE_VIEW));
            }
        }
    } catch (e) {}
}

export function* handleSessionEnded({ context, payload: { payload } }) {
    try {
        return;

        /** This was related to breakout rooms ending somehow **/
        const session = yield select((s) => selectFlag(s, "meetingSessionId"));
        const workspace = yield select((s) => selectRouteParam(s, "id"));
        if (
            payload &&
            payload.node_type === NODE_TYPE_SESSIONS &&
            session !== workspace
        ) {
            yield put(joinMeeting(context)(workspace, true));
            yield put(
                pushRouteParams({ id: workspace, ch: null, view: STAGE_VIEW })
            );
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleStartSessions({ context, payload }) {
    const { id } = payload;
    try {
        yield put(pending(id, true));
        yield request({ url: `joynt/nodes/${id}/sessions/start`, context });
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
        console.log(e);
    }
}

function* handleEndSessions({ context, payload }) {
    const { id } = payload;
    try {
        yield put(pending(id, true));
        yield request({ url: `joynt/nodes/${id}/sessions/end`, context });
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
        console.log(e);
    }
}

function* handleLog({ payload }) {
    try {
        const { message, identity, data } = payload;
        const { name } = yield select((s) =>
            selectEntity(s, "db.identities", identity)
        );
        const msg = identity
            ? `[agora] [${name}] ${message}`
            : `[agora] ${message}`;
        const args = [msg];
        if (data) args.push(data);
        console.log(...args);
    } catch (e) {}
}

function* confirmJoin(message) {
    yield put(setFlag(FLAG_SESSION_CONFIRM, message));
    const { yes } = yield race({
        yes: take("MEETING.LIVE.CONFIRM"),
        no: take("MEETING.LIVE.DENY"),
    });
    yield put(setFlag(FLAG_SESSION_CONFIRM, false));
    return !!yes;
}

function* handleGoLive({ payload }) {
    try {
        const { id, state, reason } = payload;

        let confirmedFlagId = `meeting.settingsConfirmed`;
        let liveIntentFlagId = `meeting.${id}.liveIntent`;
        let inviteFlagId = `meeting.${id}.stageInvite`;

        const role = yield select((s) => selectEventRole(s, id));

        if (role === EVENT_ROLE_RECORDING) return;

        let confirmedFlag = yield select((s) => selectFlag(s, confirmedFlagId));
        //let liveIntent = yield select(s=>selectFlag(s, liveIntentFlagId));
        let requiresConfirmation = !confirmedFlag;
        //!confirmedFlag && ["invite", "role", "auto"].indexOf(reason) > -1;

        let confirmed = true;

        if (state && requiresConfirmation)
            confirmed = yield confirmJoin({
                message: "You're about to go live",
                description: "Joining session",
                leaveLabel: ["invite", "join-request"].includes(reason)
                    ? "Not now"
                    : "Leave",
                confirmLabel: reason === "invite"
                    ? "Go on stage"
                    : "Join now",
                reason,
                id,
            });

        if (confirmed) {
            const flags = {
                //[FLAG_SESSION_RAISED_HAND]: false,
                [`meeting.${id}.live`]: !!state,
            };
            if (!state && !reason) flags[liveIntentFlagId] = false;

            if (!state) {
                flags[confirmedFlagId] = false;
            }

            if (!state && reason === "remove") {
                flags[liveIntentFlagId] = false;
                flags[FLAG_SESSION_RAISED_HAND] = false;
            }
            if (!state) {
                flags[inviteFlagId] = false;
                flags[FLAG_SESSION_RAISED_HAND] = false;
            }
            if (state && reason === "invite") {
                flags[inviteFlagId] = true;
            }
            if (state) flags[liveIntentFlagId] = true;
            console.log("FLAGS ("+reason+")", flags);
            yield put(setFlags(flags));
        } else {
            yield put(setFlags({
                [FLAG_SESSION_RAISED_HAND]: false
            }));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handlePresentation({ payload, context }) {
    try {
        const { session, id } = payload;
        const data = {
            event_presentation: id,
        };
        yield put(change("db.nodes", session, data));
        yield put(
            submitOne(context)(
                "db.nodes",
                session,
                null,
                null,
                `presentation.${session}`
            )
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleCreateSession({ context, payload }) {
    const { parent, list } = payload;

    try {
        const { name } = yield select((s) =>
            selectEntity(s, "db.nodes", parent)
        );
        yield put(pending(`db.nodes/${list}`, true));
        const data = yield select((s) =>
            createNode(s, parent, {
                name: `${name} session`,
                subtype: NODE_TYPE_EVENT,
                session_type: "public",
                hidden: false,
                event_start: Math.round(new Date().getTime() / 1000),
                event_end: null,
            })
        );
        const submitData = nodeSubmitData(data);
        const { id } = submitData;
        const {
            data: { data: response },
        } = yield request({
            context,
            url: `joynt/nodes/${id}`,
            method: "put",
            data: submitData,
        });
        yield put(storeOne("db.nodes", id, response));
        yield put(pending(`db.nodes/${list}`, false));
        yield put(listAppend("db.nodes", list, id));
        yield put(nodeLink(context)(id));
        yield put(joinMeeting(context)(id));
    } catch (e) {
        yield put(pending(`db.nodes/${list}`, false));
        console.error(e);
    }
}

function* handleScheduleSession({ context, payload }) {
    const { stage, session } = payload;
    const requestId = `db.nodes/schedule.${stage}`;
    yield put(pending(requestId, true));

    try {
        let url = `joynt/nodes/${stage}/schedule`;
        if (session) url += `?session=${session}`;
        yield request({
            url,
            method: "post",
            context,
        });
        yield put(pending(requestId, false));
    } catch (e) {
        yield put(pending(requestId, false));
        console.error(e);
    }
}

function* handleConfirmParticipation({ context, payload }) {
    const { session } = payload;
    const id = `session.participation.${session}`;

    try {
        yield put(pending(id, true));
        const response = yield request({
            url: `joynt/nodes/${session}/session/confirm`,
            method: "post",
            context,
        });
        yield put(pending(id, false));
        yield handleResponse({}, response);
    } catch (e) {
        yield put(pending(id, false));
        console.error(e);
    }
}

function* handleDenyParticipation({ context, payload }) {
    const { session } = payload;
    const id = `session.participation.${session}`;

    try {
        yield put(pending(id, true));
        const response = yield request({
            url: `joynt/nodes/${session}/session/deny`,
            method: "post",
            context,
        });
        yield put(pending(id, false));
        yield handleResponse({}, response);
    } catch (e) {
        yield put(pending(id, false));
        console.error(e);
    }
}

function* handleRevokeParticipation({ context, payload }) {
    const { session } = payload;
    const id = `session.participation.${session}`;

    try {
        yield put(pending(id, true));
        const response = yield request({
            url: `joynt/nodes/${session}/session/revoke`,
            method: "post",
            context,
        });
        yield put(pending(id, false));
        yield handleResponse({}, response);
    } catch (e) {
        yield put(pending(id, false));
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("JOINT.SET_EVENT", handleSetCurrentEvent);
    yield takeEvery("MEETING.SESSION.END", handleEndSession);
    yield takeEvery("MEETING.SESSION.NEXT", handleStartNextSession);
    yield takeEvery("MEETING.SESSION.RESET", handleResetSession);
    yield takeEvery("MEETING.AUTO_JOIN", handleAutoJoin);
    yield takeEvery("MEETING.JOIN", handleJoinMeeting);
    yield takeEvery("MEETING.LEAVE", handleLeaveMeeting);
    yield takeEvery("MEETING.RECORD.START", handleStartRecording);
    yield takeEvery("MEETING.RECORD.STOP", handleStopRecording);
    yield takeEvery("MEETING.SESSION.CALL", handleSessionStarted);
    yield takeEvery("MEETING.SESSIONS.START", handleStartSessions);
    yield takeEvery("MEETING.SESSIONS.END", handleEndSessions);
    yield takeEvery("MEETING.LIVE", handleGoLive);
    yield takeEvery("MEETING.LOG", handleLog);
    yield takeEvery("MEETING.PRESENT", handlePresentation);
    yield takeEvery("MEETING.SESSION.CREATE", handleCreateSession);
    yield takeEvery("MEETING.STAGE.SCHEDULE", handleScheduleSession);
    yield takeEvery(
        "MEETING.PARTICIPATION.CONFIRM",
        handleConfirmParticipation
    );
    yield takeEvery("MEETING.PARTICIPATION.DENY", handleDenyParticipation);
    yield takeEvery("MEETING.PARTICIPATION.REVOKE", handleRevokeParticipation);
}

import {put, takeEvery, select} from "redux-saga/effects";
import {pending} from "state/actions/ui";
import {request} from "util/api/client";
import {change, reject, reset, storeBatch} from "state/actions/data";
import {confirm} from "util/saga/feedback";
import {selectEntity, selectEntityChanges, selectList} from "state/selectors/data";
import {triggerUiNotification} from "joynt/state/actions/notifications";
import {create} from "state/actions/create";
import {listRemove} from "state/actions/list";

function* handleInvite({context, payload}) {
    const {id, emails} = payload;
    try {
        yield put(pending(id, true));
        const url = `joynt/nodes/${id}/invite`;
        const {data: {data, meta}} = yield request({
            context,
            method: 'post',
            url,
            data: {emails}}
        );
        yield put(storeBatch(data, meta));
        yield put(pending(id, false));
    } catch (e) {
        console.log(e);
        yield put(pending(id, false));
    }
}

function* handleRemoveUser({context, payload}) {
    const {id, identity} = payload;
    try {
        const {name} = yield select(s=>selectEntity(s, 'db.identities', identity));
        let confirmed = yield confirm({
            message: 'Are you sure?',
            description: `${name} will be removed from workspace`,
            cancelText: 'Cancel',
            confirmText: 'Remove'
        });
        if (!confirmed) return;
        yield put(pending(id, true));
        const url = `joynt/nodes/${id}/remove-user`;
        yield request({context, method: 'post', url, data: {identity}});
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
    }
}

function* handleBanUser({context, payload}) {
    const {id, identity} = payload;
    try {
        const {name} = yield select(s=>selectEntity(s, 'db.identities', identity));
        let confirmed = yield confirm({
            message: 'Are you sure?',
            description: `${name} will be removed from workspace and blocked from joining again`,
            cancelText: 'Cancel',
            confirmText: 'Remove'
        });
        if (!confirmed) return;
        yield put(pending(id, true));
        const url = `joynt/nodes/${id}/ban-user`;
        yield request({context, method: 'post', url, data: {identity}});
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
    }
}

function* handleChangeRole({payload: {id, data, list}}) {
    try {
        const type = 'app.node-roles';
        const {action} = data;

        if (action === 'revert') {
            yield put(reset(type, id));
            return;
        }

        const prevRoles = yield select(s=>selectList(s, type, list));

        const prev = prevRoles.filter(role => {
            return role.id === id
                || (data.identity && (role.identity === data.identity))
                || (data.email && (role.email === data.email));
        })[0] || {};

        if (action === 'remove' && prev.action === 'add') {
            yield put(listRemove(type, list, id));
            yield put(reject(type, id));
            return;
        }

        if (action === 'add') {
            if (!prev.id) {
                yield put(create(type, data, list));
            } else {
                if (prev.action !== 'add') data.action = 'change';
                yield put(change(type, prev.id, data));
            }
        } else {
            if (prev.action === 'add') data.action = 'add';
            yield put(change(type, id, data));
        }
    } catch (e) {
        console.error(e);
    }
}

function* handleUpdateRoles({context, payload, callback}) {
    const {id, publish} = payload;
    const type = `app.node-roles`;
    const list = `node-roles.${id}`;
    const data = yield select(s=>selectList(s, type, list));
    const changed = data.filter(role => !!role.action);
    let url = `joynt/nodes/${id}/roles/update`;
    try {
        yield put(pending(list, true));
        const nodeData = yield select(s=>selectEntityChanges(s, 'db.nodes', id));
        const nodeUpdate = {
            ...nodeData
        };
        if (publish) {
            nodeUpdate.published = true;
            nodeUpdate.hidden = false;
        }
        const requestData = {
            roles: changed,
            node: nodeUpdate
        };
        const response = yield request({context, method: 'post', url, data: requestData});
        if (response) {
            const {data} = response;
            const status = data.data['app.node-roles-result'][0];
            if (status.created > 0) {
                yield put(triggerUiNotification("app.node-roles-result", status));
            }
            if (callback) callback();
            yield put(storeBatch(data.data, data.meta));
        }
        yield put(pending(list, false));
    } catch (e) {
        yield put(pending(list, false));
        console.error(e);
    }
}

export default function*() {
    yield takeEvery('JOINT.INVITE', handleInvite);
    yield takeEvery('JOINT.USER.REMOVE', handleRemoveUser);
    yield takeEvery('JOINT.USER.BAN', handleBanUser);
    yield takeEvery('JOINT.ROLES.UPDATE', handleUpdateRoles);
    yield takeEvery('JOINT.ROLES.CHANGE', handleChangeRole);
}
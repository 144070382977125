import 'bootstrap.app';

import React from 'react';
import ReactDOM from 'react-dom';

import 'index.css';
import Main from './Main';
import env from 'util/env';

//redux
import { Provider } from 'react-redux';
import coreSagas from 'state/sagas';
import joyntSagas from 'joynt/state/sagas';
import {reducers} from "state/reducers";
import {configureStore} from 'state';

//router
import { ConnectedRouter } from 'connected-react-router';

//dnd
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {configureBroadcasting} from "util/broadcasting";

configureBroadcasting(env('ECHO_SERVER'));

const sagas = [
    ...coreSagas,
    ...joyntSagas
]


const {store, history} = configureStore({}, {reducers, sagas});

const render = Component => {
    return ReactDOM.render(<DndProvider backend={HTML5Backend}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Component/>
            </ConnectedRouter>
        </Provider>
    </DndProvider>, document.getElementById('root'));
};

render(Main);

if (module.hot) {
    module.hot.accept('./Main', () => {
        const NextApp = require('./Main').default;
        render(NextApp);
    });
}
import chromeExtension from "./chrome-extension";
import account from "./account";
import activity from "./activity";
import joint from "./joint";
import calls from "./calls";
import messages from "./messages";
import posts from "./posts";
import meetings from "./meetings";
// FIXME: review whether this saga will be used:
// import meetingsDebug from "./meetings-debug";
import edges from "./edges";
import presence from "./presence";
import notifications from "./notifications";
import reactions from "./reactions";
import members from "./members";
import nodes from "./nodes";
import router from "./router";
import joyride from "./joyride";
import witness from "joynt/components/Witness/sagas";
import roles from "joynt/components/Roles/state/sagas";
import sessionFlow from "joynt/components/SessionFlow/sagas";

import validation from "state/sagas/validation";
import validationConfig from "joynt/config/validation";

export default [
    validation(validationConfig),
    account,
    chromeExtension,
    notifications,
    reactions,
    activity,
    joint,
    edges,
    calls,
    messages,
    posts,
    meetings,
    witness,
    // meetingsDebug,
    presence,
    members,
    nodes,
    router,
    joyride,
    roles,
    sessionFlow,
];

import { createReducer } from "state/helpers/create-reducer";
import { setIn } from "lodash-redux-immutability";

const actions = {
    'DATA.STORE.LIST': (state, {payload}) => {
        const { type, list, meta } = payload;
        return setIn(state, [type, list], meta);
    },
    'DATA.LIST.RESET': (state, {payload}) => {
        const { type, list } = payload;
        let next = setIn(state, [type, list], []);
        return setIn(next, ['_props', type, list], {});
    },
    'PAGINATION.SET_PAGE': (state, {payload}) => {
        const { type, list, page } = payload;
        return setIn(state, [type, list, 'current'], page);
    },
    'LIST.UPDATED': (state, {payload}) => {
        const { type, list, timestamp} = payload;
        return setIn(state, [type, list, 'updated'], timestamp);
    },
    'LIST.REGISTER_PROPS': (state, {payload}) => {
        const { type, list } = payload;
        return setIn(state, ['_props', type, list], payload);
    }
    /*'DATA.STORE.LIST_IDS': (state, {payload}) => {
        const { type, list, meta } = payload;
        return setIn(state, [type, list], meta);
    }*/
};

export default createReducer({}, actions);
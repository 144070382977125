import env from "util/env";

const urlB64ToUint8Array = base64String => {
    if (!base64String) return null;
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray
};

const requestNotificationsPermission = async () => {
    const permission = await window.Notification.requestPermission();
    if (permission !== 'granted') {
        return false;
    }
    return permission;
};

export const subscribeUserToPush = async (registration) => {
    const subscriptionKey = urlB64ToUint8Array(env('VAPID_PUBLIC_KEY'));

    const options = {
        applicationServerKey: subscriptionKey,
        userVisibleOnly: true
    };

    if (registration.active) {
        return await registration.pushManager.subscribe(options);
    }
};

export async function register() {
    if (!('serviceWorker' in navigator)) {
        return;
    }

    if (!('PushManager' in window)) {
        return;
    }

    const url = `${process.env.PUBLIC_URL}/sw.js`;

    return navigator.serviceWorker.register(url).then(async registration => {

        await navigator.serviceWorker.ready

        const permission = await requestNotificationsPermission();

        if (permission) {
            return await subscribeUserToPush(registration)
        } else {
            return await registration.unregister();
        }
    }).catch(e => console.log("error during registration", e));
}

export async function unregister() {
    if ('serviceWorker' in navigator) {
       return navigator.serviceWorker.ready.then(registration => {
           return registration.pushManager.getSubscription().then(async s => {
                if (s) {
                    await s.unsubscribe();
                }
                return await registration.unregister();
            });
        });
    }
}

import localStorageSaga from "./local-storage";
import bootstrap from "./bootstrap";
import modal from "./modal";
import create from "./create";
import copy from "./copy";
import data from "./data";
import deleteSaga from "./delete";
import api from "./api";
import auth from "./auth";
import filters from "./filters";
import media from "./media";
import upload from "./upload";

import theme from "cms/state/sagas/theme";

import validation from "./validation";
import validationConfig from "config/validation";

const coreSagas = [create, copy, data, deleteSaga, api, theme];

export default [
    ...coreSagas,
    validation(validationConfig),
    localStorageSaga,
    bootstrap,
    modal,
    auth,
    filters,
    media,
    upload,
];

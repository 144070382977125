import { takeEvery, select, fork, put } from "redux-saga/effects";
import {
  selectAliasedEntity,
  selectEntity,
  selectList,
} from "state/selectors/data";
import { loadWebfont } from "util/theme/font-loader";
import hexRgb from "hex-rgb";
import hexHsl from "hex-to-hsl";
import less from "less";
import { pending } from "state/actions/ui";
import { change } from "state/actions/data";
import { error } from "util/saga/feedback";
import {
  convertPresetToLessVars,
  responsivePreviewBreakpoints,
} from "util/theme";
import { selectRouteParam } from "state/selectors/router";

const skipRender = [
  "less/imports.less",
  ".htaccess-generated",
  "js/custom.js",
  "schema.json",
];

function hexRgbStr(hex) {
  const rgb = hexRgb(hex, { format: "array" });
  return rgb.slice(0, 3).join(",");
}

function hexHsStr(hex) {
  const hsl = hexHsl(hex);
  hsl[1] = hsl[1] + "%";
  return hsl.slice(0, 2).join(",");
}

function setDocumentCssVariables(variables, target) {
  if (!target) return;
  if (target === true) target = document.documentElement;
  for (let k in variables) {
    let varName = "--" + k;
    let value = variables[k];
    target.style.setProperty(varName, value);
  }
}

function* handleApplyPreset(action) {
  try {
    const { id, target, loadFonts, dark } = action.payload;
    const preset = yield select((store) =>
      selectEntity(store, "console.presets", id)
    );

    let vars = {};
    let mapping = dark ? "dark" : preset.mapping;

    vars["primary-color"] = preset.color_scheme.primary_color;
    vars["success-color"] = preset.color_scheme.success_color;
    vars["dark-color"] = preset.color_scheme.dark_color;
    vars["default-color"] =
      mapping === "dark"
        ? preset.color_scheme.dark_color
        : preset.color_scheme.light_color;
    vars["invert-color"] =
      mapping === "dark"
        ? preset.color_scheme.light_color
        : preset.color_scheme.dark_color;

    vars["primary-color-rgb"] = hexRgbStr(vars["primary-color"]);
    vars["success-color-rgb"] = hexRgbStr(vars["success-color"]);

    vars["primary-color-hs"] = hexHsStr(vars["primary-color"]);
    vars["success-color-hs"] = hexHsStr(vars["success-color"]);

    const fontFamilies = ["heading_font", "body_font", "decorative_font"];

    if (loadFonts && preset.typography) {
      let typo = preset.typography || {};
      fontFamilies.forEach((key) => {
        if (typo[key]) {
          let varName = key.replace(/_/g, "-") + "-family";
          vars[varName] = typo[key].family;
          loadWebfont(typo[key]);
        }
      });
    }

    setDocumentCssVariables(vars, target);
  } catch (e) {
    console.log(e);
  }
}

function* handleLoadWebfont({ payload }) {
  try {
    const { family, source } = payload;
    yield loadWebfont({ family, source });
  } catch (e) {
    console.log(e);
  }
}

function* renderLess(styles, options) {
  try {
    const result = yield less.render(styles, options);
    const containerId = "less:static-less-style";
    let container = document.getElementById(containerId);
    if (!container) {
      container = document.createElement("style");
      container.setAttribute("id", containerId);
      container.setAttribute("type", "text/css");
      document
        .getElementById("theme-shadow-root")
        .shadowRoot.appendChild(container);
    }
    if (result.css) {
      container.textContent = result.css;
    }
    yield put(pending("less-render", false));
  } catch (e) {
    console.log(e);
    yield error(e);
    yield put(pending("less-render", false));
  }
}

function* handleCompileStyles({ payload }) {
  try {
    yield put(pending("less-render", true));
    const allFiles = yield select((store) =>
      selectList(store, "theme.files", "default")
    );
    const renderBlacklist = skipRender;
    const files = allFiles.filter(
      (file) => renderBlacklist.indexOf(file.id) === -1
    );
    const stylesText = files.map((file) => file.content).join("\r\n\r\n");
    const preset = yield select((store) =>
      selectAliasedEntity(store, "console.presets", "@websitePreset")
    );
    const device = yield select((store) =>
      selectRouteParam(store, "device", "desktop")
    );
    const vars = {
      ...convertPresetToLessVars(preset),
      ...responsivePreviewBreakpoints(device),
    };
    const options = {
      modifyVars: vars,
    };
    yield fork(renderLess, stylesText, options);
  } catch (e) {
    console.log(e);
    yield error(e);
    yield put(pending("less-render", false));
  }
}

function* handleImportColorScheme({ payload }) {
  try {
    const type = "console.presets";
    const { from, to, typeTo } = payload;
    const fromData = yield select((store) => selectEntity(store, type, from));
    const update = { color_scheme: fromData.color_scheme };
    yield put(change(typeTo || type, to, update));
  } catch (e) {
    console.log(e);
  }
}

function* handleImportTypography({ payload }) {
  try {
    const type = "console.presets";
    const { from, to, typeTo } = payload;
    const fromData = yield select((store) => selectEntity(store, type, from));
    const update = { typography: fromData.typography };
    yield put(change(typeTo || type, to, update));
  } catch (e) {
    console.log(e);
  }
}

export default function* () {
  yield takeEvery("THEME.APPLY_PRESET", handleApplyPreset);
  yield takeEvery("THEME.LOAD_WEBFONT", handleLoadWebfont);
  yield takeEvery("THEME.COMPILE_STYLES", handleCompileStyles);
  yield takeEvery("THEME.IMPORT_COLOR_SCHEME", handleImportColorScheme);
  yield takeEvery("THEME.IMPORT_TYPOGRAPHY", handleImportTypography);
}

import { takeEvery, put, select } from "redux-saga/effects";
import { pushRouteParams, pushRoute } from "state/actions/router";
import { selectEntity } from "state/selectors/data";
import { change, storeOne } from "state/actions/data";
import { fetch, submitOne } from "state/actions/api";

import {
    NODE_TYPE_EXTERNAL,
    ROUTE_CHANNEL,
    ROUTE_NAV,
    ROUTE_VIEW,
    STAGE_VIEW,
} from "joynt/config";

import { pending } from "state/actions/ui";
import { request } from "util/api/client";
import { appendQueryParams } from "util/uri";
import { parseExternalUrl } from "joynt/views/External";

function* handleNodeLink({ payload, context }) {
    const { id, channel, view: nextView } = payload;
    try {
        const params = {};
        const prevParams = yield select((s) => s.router.query);
        const prevPath = yield select((s) => s.router.location.pathname);

        if (nextView) params[ROUTE_VIEW] = nextView;
        if (channel) params[ROUTE_CHANNEL] = channel;
        if (prevParams[ROUTE_NAV]) params[ROUTE_NAV] = prevParams[ROUTE_NAV];

        const node = yield select((s) => selectEntity(s, "db.nodes", id));
        const isExternal = node.subtype === NODE_TYPE_EXTERNAL;

        if (isExternal) {
            let externalData = parseExternalUrl(node.external_url);
            if (externalData.type === "url") {
                window.open(externalData.url);
                return;
            }
        }

        const { edges_ref: edges } = node;

        const pushLocation = (location) => {
            const path = location.replace("/", prevPath);
            return pushRoute(appendQueryParams(path, params));
        };

        if (!id) {
            yield put(pushLocation("/"));
            //yield put(pushRoute(appendQueryParams('/', params)));
            return;
        }

        if (edges && edges.uri) {
            if (params.view === STAGE_VIEW) {
                yield put(pushLocation(edges.uri_session));
                //yield put(pushRoute(appendQueryParams(edges.uri_session, params)));
            } else {
                yield put(pushLocation(edges.uri));
                //yield put(pushRoute(appendQueryParams(edges.uri, params)));
            }
        } else {
            yield put(pending("pending", true));
            const {
                data: { data: nodeData },
            } = yield request({
                url: `joynt/nodes/${id}`,
                context,
            });
            yield put(pending("pending", false));
            if (!nodeData.edges_ref) return;
            yield put(storeOne("db.nodes", nodeData.id, nodeData));
            if (params.view === STAGE_VIEW) {
                yield put(pushLocation(nodeData.edges_ref.uri_session));
                //yield put(pushRoute(appendQueryParams(nodeData.edges_ref.uri_session, params)));
            } else {
                yield put(pushLocation(nodeData.edges_ref.uri));
                //yield put(pushRoute(appendQueryParams(nodeData.edges_ref.uri, params)));
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleCancelSelect() {
    try {
        yield put(pushRouteParams({ select: null }));
    } catch (e) {
        console.log(e);
    }
}

function* handleAssignRole({ context, payload }) {
    try {
        const { parent, identity, role } = payload;
        const data = {};
        const prev = yield select((store) =>
            selectEntity(store, "db.nodes", parent)
        );
        let key = "event_speakers";
        if (role === "moderator") key = "event_moderators";
        if (role === "admin") key = "recipient_identities";
        let prevValue = prev[key] || [];
        let nextValue = prevValue.slice();
        if (nextValue.indexOf(identity) > -1) {
            nextValue = prevValue.filter((id) => id !== identity);
        } else {
            nextValue.push(identity);
        }
        data[key] = nextValue;
        yield put(change("db.nodes", parent, data));
        yield put(submitOne(context)("db.nodes", parent));
    } catch (e) {
        console.log(e);
    }
}

function* handleBootstrap({ context, payload }) {
    try {
        const { id, join } = payload;

        let url = join
            ? `joynt/bootstrap?_id=${id}&join=true`
            : `v2/joynt/user-account`;

        yield put(
            fetch(context)(null, null, url, null, null, null, "bootstrap")
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleShare({ context, payload }) {
    const { id, to } = payload;
    try {
        yield put(pending(id, true));
        const url = `joynt/nodes/${id}/share?to=${to}`;
        yield request({ url: url });
        yield put(pending(id, false));
    } catch (e) {
        yield put(pending(id, false));
    }
}

export default function* () {
    yield takeEvery("JOINT.NODE_LINK", handleNodeLink);
    yield takeEvery("JOINT.NODE_SELECT_CANCEL", handleCancelSelect);
    yield takeEvery("JOINT.ASSIGN_ROLE", handleAssignRole);
    yield takeEvery("JOINT.BOOTSTRAP", handleBootstrap);
    yield takeEvery("JOINT.SHARE", handleShare);
}

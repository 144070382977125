import { takeEvery, put, select } from "redux-saga/effects";
import { post, fetch } from "state/actions/api";
import { error } from "util/saga/feedback";
import { pushRoute } from "state/actions/router";
import { handlePost } from "state/sagas/api";
import { selectEntity } from "state/selectors/data";
import { denormalize } from "state/util";

function* handleSubmitConfigureAccount({ payload, context }) {
    try {
        const { id, node } = payload;
        const endpoint = "joynt/welcome";
        const data = yield select((store) => {
            return denormalize(store, "ui.forms", "welcome");
            //selectEntity(s, "ui.forms", "welcome")
        });

        yield handlePost(
            post(context)(
                endpoint,
                {
                    ...data,
                    join: id || false,
                    node: node || false,
                },
                "welcome"
            )
        );

        const path = window.location.pathname;

        let url = path === "/welcome" ? "/" : path + window.location.search;
        if (id && path === "/welcome") url += `?id=${id}`;

        yield put(fetch(context)(null, null, "v2/joynt/user-account"));
        yield put(pushRoute(url));
    } catch (e) {
        yield error(e);
    }
}

export default function* () {
    yield takeEvery("JOINT.ACCOUNT.CONFIGURE", handleSubmitConfigureAccount);
}

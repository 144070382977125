import {
    call,
    put,
    putResolve,
    race,
    select,
    take,
    takeEvery,
} from "redux-saga/effects";
import { selectEdges } from "joynt/state/selectors/joint";
import { selectList } from "state/selectors/lists";
import { selectEntity } from "state/selectors/data";
import { change, pathChange } from "state/actions/data";
import { listAppend, listRemove, reload } from "state/actions/list";
import { post, submitOneCallback } from "state/actions/api";
import {pushRoute, pushRouteParams} from "state/actions/router";
import { bootstrap, nodeSelectCancel } from "joynt/state/actions";
import { handlePost } from "state/sagas/api";
import { deleteSuccess } from "state/actions/delete";
import { confirm } from "util/saga/feedback";
import { pending } from "state/actions/ui";

function* handleToggleSubscription({ payload, context }) {
    try {
        const { from, to } = payload;
        const list = ["db.posts", from].join(".");
        const pendingId = ["subscribe", to].join(".");
        const data = yield select((store) =>
            selectEntity(store, "db.nodes", from)
        );
        let value = data.subscribe ? data.subscribe.slice() : [];
        if (value.indexOf(to) > -1) {
            value = value.filter((id) => id !== to);
        } else {
            value.push(to);
        }
        yield putResolve(change("db.nodes", from, { subscribe: value }));
        const callback = function* () {
            yield put(pushRouteParams({ stream: null }));
            yield put(reload(context)("db.posts", list));
        };
        yield putResolve(
            submitOneCallback(context)(
                callback,
                "db.nodes",
                from,
                null,
                null,
                pendingId
            )
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleMoveNode({ context, payload }) {
    try {
        const { id } = payload;
        let target = payload.target;
        if (!target) {
            yield put(pushRouteParams({ select: id }));
            const { confirm } = yield race({
                confirm: take("JOINT.NODE_SELECT"),
                close: take("JOINT.NODE_SELECT_CANCEL"),
            });
            if (confirm) {
                target = confirm.payload.id;
                yield call(handlePost, {
                    context,
                    payload: {
                        url: `joynt/nodes/${id}/edge`,
                        data: { edge_type: "child,stem", from: target },
                        submitId: "move-node",
                    },
                });
                yield put(listRemove("db.nodes", "db.nodes.graph", id));
                yield put(nodeSelectCancel());
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* selectNode(value) {
    if (value) return value;
    yield put(pushRouteParams({ select: true }));
    const { confirm } = yield race({
        confirm: take("JOINT.NODE_SELECT"),
        close: take("JOINT.NODE_SELECT_CANCEL"),
    });
    if (!confirm) {
        yield put(nodeSelectCancel());
        return;
    }
    yield put(nodeSelectCancel());
    return confirm.payload.id;
}

function* handleJoin({ context, payload: { from, to } }) {
    try {
        from = yield selectNode(from);
        if (!from) return;
        yield put(pending(to, true));
        yield call(handlePost, {
            context,
            payload: {
                url: `joynt/nodes/${to}/edges/join`,
                data: { from: from },
                submitId: ["edge", "joined", to].join("."),
            },
        });
        yield put(bootstrap(context)(to));
        yield put(pending(to, false));
    } catch (e) {
        yield put(pending(to, false));
        console.error(e);
    }
}

function* handleLeave({ context, payload: { node } }) {
    try {
        const { name } = yield select((s) => selectEntity(s, "db.nodes", node));
        if (
            yield confirm({
                message: `Leave ${name}?`,
                description:
                    "When you leave you won't be able to access this workspace anymore.",
                confirmText: "Leave",
            })
        ) {
            yield put(pending(node, true));
            yield call(handlePost, {
                context,
                payload: {
                    url: `joynt/nodes/${node}/edges/leave`,
                    submitId: ["edge", "joined", node].join("."),
                },
            });
            yield put(pushRoute("/"));
            yield put(deleteSuccess("db.nodes", node));
            yield put(bootstrap(context)(node));
            yield put(pending(node, false));
        }
    } catch (e) {
        yield put(pending(node, false));
        console.error(e);
    }
}

function* handleCreateEdge({ context, payload }) {
    try {
        const { to, edgeType } = payload;
        let from = yield selectNode(payload.from);
        if (!from) return;
        yield call(handlePost, {
            context,
            payload: {
                url: `joynt/edges`,
                data: { edge_type: edgeType, from: from, to: to },
                submitId: ["edge", edgeType, to].join("."),
            },
        });
        yield put(nodeSelectCancel());
        if (edgeType === "joined") {
            yield put(bootstrap(context)(to));
        }
    } catch (e) {
        console.log(e);
    }
}

function* handleToggleKeep({ context, payload }) {
    try {
        const { from, to } = payload;

        const edges = yield select((store) => selectEdges(store, to));

        const list = `db.nodes.${from}.channels`;
        const channels = yield select((store) =>
            selectList(store, "db.nodes", list)
        );
        const { node_type } = yield select((store) =>
            selectEntity(store, "db.nodes", to)
        );
        const { parent } = yield select((store) => selectEdges(store, to));
        const path = ["db.nodes", to, "edges_ref", "keep"];
        const keepValue = !edges.keep ? from : null;
        yield put(pathChange(path, keepValue));

        if (!edges.keep && channels.indexOf(to) === -1) {
            yield put(listAppend("db.nodes", list, to));
        } else {
            if (parent !== from || node_type === "stem") {
                yield put(listRemove("db.nodes", list, to));
            }
        }

        yield put(
            post(context)("joynt/edges", {
                edge_type: "keep",
                from: from !== "auto" ? from : null,
                to: to,
            })
        );
    } catch (e) {
        console.log(e);
    }
}

function* handleFollow({ context, payload }) {
    const { to } = payload; //from
    try {
        yield put(
            post(context)(
                "joynt/edges",
                {
                    edge_type: "follow",
                    from: null,
                    to,
                },
                `${to}.follow`
            )
        );
    } catch (e) {
        console.error(e);
    }
}

export default function* () {
    yield takeEvery("JOINT.CREATE_EDGE", handleCreateEdge);
    yield takeEvery("JOINT.TOGGLE_SUBSCRIPTION", handleToggleSubscription);
    yield takeEvery("JOINT.MOVE_NODE", handleMoveNode);
    yield takeEvery("JOINT.KEEP", handleToggleKeep);
    yield takeEvery("JOINT.MEMBERS.JOIN", handleJoin);
    yield takeEvery("JOINT.MEMBERS.LEAVE", handleLeave);
    yield takeEvery("JOINT.FOLLOW", handleFollow);
}

import {confirm} from "util/saga/feedback";
import {put} from "redux-saga/effects";

import {leaveMeeting} from "joynt/state/actions/meetings";
import {pushRouteParams} from "state/actions/router";
import {MODAL_WITNESS_LEAVE} from "./../modals";

export function* handleEndSession({context, payload}) {
    const {id} = payload;

    try {
        if (yield confirm({
            message: "Are you sure you want to quit?",
            cancelText: "Continue session",
            confirmText: "Quit session now"
        })) {
            yield put(leaveMeeting(id));
            yield put(pushRouteParams({page: MODAL_WITNESS_LEAVE}))
        }
    } catch (e) {
        console.error(e);
    }
}
import {put, select} from "redux-saga/effects";
import {post} from "state/actions/api";
import {selectUserNodeIdentity} from "joynt/state/selectors/joint";
import {selectEntity} from "state/selectors/data";

const flowTransition = {
    'start': 'offline-witness-solo-1',
    'describe-task-a': 'offline-witness-solo-1',
    'describe-task-b-intro': 'offline-witness-solo-1',
    'describe-task-b': 'offline-witness-solo-1',
    'pre-task': 'offline-witness-solo-1',
    'offline-in-progress': 'offline-in-progress',
    'summary-a-intro': 'offline-witness-solo-congrats',
    'summary-a': 'offline-give-badge',
    'give-badge-a': 'offline-give-badge',
    'summary-b-intro': 'offline-give-badge',
    'summary-b': 'offline-give-badge',
    'give-badge-b': 'offline-give-badge',
    'wrap-up': 'offline-end'
}

export function* handleSwitchToSolo({context, payload}) {
    const {id} = payload;

    try {
        const identity = yield select(s => selectUserNodeIdentity(s, id));
        const url = `joynt/nodes/${id}`;
        const {
            session_flow_segment: current,
            session_flow_segment_started: started
        } = yield select(s => selectEntity(s, "db.nodes", id));

        const next = flowTransition[current] || 'start';

        //(new Date()).getTime()/1000

        const data = {
            id,
            'session_flow': 'witness-solo',
            session_flow_segment: next,
            session_flow_segment_started: started,
            'public': false,
            'published': false,
            'identity': identity,
            visible_for: []
        }

        const submitId = `${id}.flow`;
        yield put(post(context)(url, data, submitId, "db.nodes"));
    } catch (e) {
        console.error(e);
    }
}
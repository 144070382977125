import { select, put } from "redux-saga/effects";
import { selectNodeFlowPath } from "./../selectors";
import { selectFlowSegment } from "./../selectors";
import { joinMeeting, leaveMeeting } from "joynt/state/actions/meetings";
import { selectFlag } from "state/selectors/ui";
import { FLAG_SESSION_ID, FLAG_SESSION_PENDING } from "joynt/config";
import { confirm } from "util/saga/feedback";

export function* handleFlowSegmentTransition({ context, payload }) {
    const { id } = payload;
    try {
        const fullPath = yield select((s) => selectNodeFlowPath(s, id));
        const [flowId, path] = fullPath.split(".");
        const config = yield select((s) => selectFlowSegment(s, flowId, path));

        const currentActiveSession = yield select((s) =>
            selectFlag(s, FLAG_SESSION_ID)
        );

        const pendingSession = yield select((s) =>
            selectFlag(s, FLAG_SESSION_PENDING)
        );

        const currentSession = currentActiveSession || pendingSession;

        if (config.disconnect) {
            yield put(leaveMeeting(id));
        }

        if (config.notify) {
            yield confirm(config.notify);
        }

        if (config.pull && currentSession !== id) {
            if (yield confirm(config.pull)) {
                yield put(joinMeeting(context)(id, true));
            }
        }
    } catch (e) {
        console.error(e);
    }
}

import { createContentStateFromHTML } from "./createContentStateFromHTML";
import { convertToRaw } from "draft-js";

export function convertPostsToRawState(posts) {
    let raw = { blocks: [], entityMap: [] };

    if (posts[0]?.content) {
        let state = createContentStateFromHTML(posts[0].content);
        raw = convertToRaw(state);
    }

    return {
        ...raw,
        version: "draft-js.raw",
    };
}

import {put, takeEvery} from "redux-saga/effects";
import {addUser, removeUser} from "joynt/state/actions/presence";
import {request} from "util/api/client";

function* handleUserJoined({payload}) {
    try {
        const {id, user} = payload;
        //console.log('User joined', presenceList(id), user);
        yield put(addUser(id, user));
    } catch (e) {
        console.log(e);
    }
}

function* handleUserLeft({payload}) {
    try {
        const {id, user} = payload;
        yield put(removeUser(id, user));
    } catch (e) {
        console.log(e);
    }
}

function* handlePublishPresence({context, payload}) {
    try {
        const {data} = payload;
        const url = `joynt/nodes/${data.id}/presence`;
        yield request({method: 'post', data, url, context});
    } catch (e) {
        console.log(e);
    }
}

/*function* handleConnected({payload}) {
    try {
        const {id, users} = payload;
    } catch (e) {
        console.log(e);
    }
}*/

export default function*() {
    yield takeEvery('JOINT.PRESENCE.USER_JOINED', handleUserJoined);
    yield takeEvery('JOINT.PRESENCE.USER_LEFT', handleUserLeft);
    yield takeEvery('JOINT.PRESENCE.PUBLISH', handlePublishPresence);
    //yield takeEvery('JOINT.PRESENCE.CONNECTED', handleConnected);
}
import {call, takeEvery, fork, put, delay} from "redux-saga/effects";
import {uploadProgressWatcher} from "util/saga/upload";
import * as actions from "state/actions/upload";
import * as ui from 'util/saga/feedback';
import {upload} from 'util/api/saga';
import {change, create, pathAppend, pathDetach, reset, storeOne} from "state/actions/data";
import { listAppend } from "state/actions/list";
import uuid from 'uuid/v4';
import {request} from "util/api/client";
import axios from 'axios';
import {createUploader} from "util/saga/upload";
import {deleteSuccess} from "state/actions/delete";

/*function* handleUpload(action) {
    const { payload, callback, context } = action;
    const { resource, path, data } = payload;
    const { id, file } = data;
    try {
        const filePreview = URL.createObjectURL(file);
        const formData = new FormData();
        formData.append("id", id);
        formData.append("file", file);
        yield put(create(resource, {
            id,
            file: filePreview
        }));
        yield ui.pending(id, true);
        const [uploadPromise, chan] = yield call(upload, resource, formData, context);
        yield fork(uploadProgressWatcher, chan, id, actions.uploadProgress);
        if (path) yield put(pathAppend(path, id));
        const res = yield call(() => uploadPromise);

        yield put(storeOne(resource, id, res.data.data));
        yield put(reset(resource, id));
        URL.revokeObjectURL(file);
        yield ui.pending(id, false);
        if (callback) callback(res.data.data);
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}*/


/**
 * Storing cancel tokens for upload requests
 *
 * @type {{}}
 */
const pendingRequests = {};

/**
 * Upload file directly to S3 using PutObject and presigned uri
 *
 * @param action
 */
function* handleUploadS3(action) {
    const { payload, callback, context } = action;
    const { resource, path, data } = payload;
    const { id, file } = data;
    try {
        yield ui.pending(id, true);
        const filePreview = URL.createObjectURL(file);
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        pendingRequests[id] = source;

        yield put(create(resource, {id, file: filePreview}));
        if (path) yield put(pathAppend(path, id));

        yield put(storeOne('app.uploads', id, {
            id,
            media: id,
            status: 'init',
            file: file.name,
            filetype: file.type,
            size: file.size
        }));

        const signResponse = yield request({
            context,
            url: 'db/media/s3-upload',
            method: 'post',
            data: {
                id,
                filename: file.name,
                filetype: file.type
            },
            cancelToken: source.token
        });

        if (signResponse.data.error) throw new Error(signResponse.data.error);
        const {data: {data: {uri: signedUri, media}}} = signResponse;

        const uploadFn = () => createUploader(progress => {
            return new Promise((resolve, reject) => {
                axios.put(signedUri, file, {
                    onUploadProgress: progress,
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': file.type
                    }
                }).then(resolve).catch(reject);
            });
        });

        yield put(change('app.uploads', id, {
            status: 'in-progress',
            uploadRequest: source
        }));

        const [uploadPromise, chan] = yield call(uploadFn);

        yield put(storeOne(resource, id, {...media, file: filePreview}));
        yield fork(uploadProgressWatcher, chan, id, actions.uploadProgress);

        yield call(()=>uploadPromise);
        yield put(change('app.uploads', id, {status: 'success'}));

        yield put(storeOne(resource, id, media));
        yield put(reset(resource, id));
        yield put(deleteSuccess('app.uploads', id));
        URL.revokeObjectURL(file);
        yield ui.pending(id, false);
        if (callback) callback(media);

    } catch (e) {
        if (path) yield put(pathDetach(path, id));
        yield ui.pending(id, false);
        if (axios.isCancel(e)) {
            yield put(change('app.uploads', id, {status: 'cancelled'}));
        } else {
            yield ui.error(e);
            yield put(change('app.uploads', id, {status: 'failed'}));
        }
        yield delay(2000);
        yield put(deleteSuccess('app.uploads', id));
    }
}

function* handleCancelUpload({payload: {id}}) {
    try {
        if (yield ui.confirm('Delete file?')) {
            if (pendingRequests[id]) yield call(() => pendingRequests[id].cancel());
        }
    } catch (e) {
        yield ui.error(e);
    }
}

function* handleBrowserUpload(id, type, list, file, path, context) {
    try {
        const uri = 'cms/browser';
        const formData = new FormData();
        formData.append('id', id);
        formData.append('file', file);
        formData.append('current', path);
        const [uploadPromise, chan] = yield call(upload, uri, formData, context);
        yield fork(uploadProgressWatcher, chan, id, actions.uploadProgress);
        const res = yield uploadPromise;
        yield put(reset(type, id));
        yield put(storeOne(type, id, res.data.data));
        yield put(listAppend(type, list, id));
        URL.revokeObjectURL(file);
        yield ui.pending(id, false);
    } catch (e) {
        yield ui.pending(id, false);
        yield ui.error(e);
    }
}

function* addFilePreview(type, list, file) {
    const id = uuid();
    const filePreview = URL.createObjectURL(file);
    yield put(create(type, {
        id,
        file: filePreview
    }));
    yield ui.pending(id, true);
    yield put(listAppend(type, [list, 'filter'].join('/'), id));
    return id;
}

function* handleBrowserUploadFiles({payload, context}) {
    try {
        const { type, list, files, path } = payload;
        const ids = [];
        for (let i = 0; i < files.length; i++) {
            let id = yield call(addFilePreview, type, list, files[i]);
            ids.push(id);
        }
        for (let i = 0; i < files.length; i++) {
           yield call(handleBrowserUpload, ids[i], type, list, files[i], path, context);
        }
    } catch (e) {

    }
}

export default function*() {
    yield takeEvery("FILES.UPLOAD", handleUploadS3);
    yield takeEvery("FILES.UPLOAD.BROWSER", handleBrowserUploadFiles);
    yield takeEvery("FILES.UPLOAD.CANCEL", handleCancelUpload);
}
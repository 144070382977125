import {takeEvery, call, select, put} from "redux-saga/effects";
import {selectCurrentUserId} from "state/selectors/app";
import {register, unregister} from "swRegister";
import {post} from "state/actions/api";
import {setFlag, showNotification} from "state/actions/ui";
import {selectEntityData} from "state/selectors/data";
import {pathDetach, pathUnset, storeOne} from "state/actions/data";
import uuid from "uuid/v4";
import {getIn} from "lodash-redux-immutability";

function* handleSubscribe({payload, context}) {

    try {
        const user = yield select(s=>selectCurrentUserId(s));
        const subscription = yield call(register, user);
        const url = `joynt/push-subscriptions/register`;
        if (subscription) {
            yield put(post(context)(url, {
                user,
                subscription
            }, 'notifications-register'));
            yield put(setFlag('notifications.push', true));
        }
    } catch (e) {
        yield handleUnsubscribe({payload, context});
    }
}

function* handleUnsubscribe({payload, context}) {
    try {
        yield call(unregister);
        yield put(setFlag('notifications.push', false));
    } catch (e) {
        console.log('Error in handle unsubscribe', e);
    }
}

function* handleUpdateStatus({payload: {status}}) {
    try {
        const type = 'app.notification-status';
        const prev = yield select(s => selectEntityData(s, type, status.id));
        const recipients = prev.recipients || {};
        const next = {...prev, recipients: {
            ...recipients,
            ...status.recipients
        }};
        yield put(storeOne(type, status.id, next));
    } catch (e) {
        console.error(e);
    }
}

function* handleTriggerUiNotification({payload}) {
    try {
        const {data, type} = payload;
        const notification = {
            id: uuid(),
            notification_type: type,
            ...data
        }
        yield put(storeOne("db.notifications", notification.id, notification));
        yield put(showNotification(notification.id));
    } catch (e) {
        console.error(e);
    }
}

function* handleMarkAsSeen({payload, context}) {
    const {id} = payload;
    try {
        const user = yield select(s => selectCurrentUserId(s));
        const path = ['db.notification-stats', user, 'all', 'nodes', id];
        const prev = yield select(s => getIn(s, ['data'].concat(path)));
        yield put(pathUnset(path));
        if (prev) {
            yield put(post(context)(`joynt/nodes/${id}/seen`, {}, 'notification-stats'));
        }
    } catch (e) {
        console.error(e);
    }
}

export default function*() {
    yield takeEvery('NOTIFICATIONS.SUBSCRIBE', handleSubscribe);
    yield takeEvery('NOTIFICATIONS.UNSUBSCRIBE', handleUnsubscribe);
    yield takeEvery('NOTIFICATIONS.STATUS', handleUpdateStatus);
    yield takeEvery('NOTIFICATIONS.TRIGGER', handleTriggerUiNotification);
    yield takeEvery('NOTIFICATIONS.MARK_AS_SEEN', handleMarkAsSeen);
}
